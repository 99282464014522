<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Solutions Details</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Solutions Details</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div> -->

<!-- <section class="services-details-area ptb-100"> -->
<div class="page-title-area">

    <div class="container">
        <div class="registration-container">
            <div class="text-section">
                <h1><span>Trademark & IPR</span> Protect Your Innovations

                </h1>
                <p>
                    LegalSetup specializes in Trademark and Intellectual Property Rights (IPR) services to safeguard
                    your creative assets. Our comprehensive offerings include Trademark Registration to establish your
                    brand identity, Copyright protection for your original works, and Patent services to secure your
                    inventions. We also provide guidance on handling Infringement issues, ensuring your intellectual
                    property remains protected. Trust us to help you navigate the complexities of IPR, so you can focus
                    on innovation and growth.


                </p>
                <div class="stats">
                    <div>
                        <h2>1000+</h2>
                        <p>Happy Customers</p>
                    </div>
                    <div>
                        <h2>20 +</h2>
                        <p>CA & Lawyers</p>
                    </div>
                    <div>
                        <h2>500 +</h2>
                        <p>Filings Every Month</p>
                    </div>
                </div>

                <div class="buttons">
                    <a href="tel:+91 9911566199" target="_blank"><button class="btn-schedule">Schedule Free
                            Consultation</button></a>
                    <button routerLink="/about" class="btn-info">See How It Works</button>
                </div>
            </div>
            <div class="image-section">
                <img src="../../../assets/images/trademarkcerti.jpg" alt="Certificate">
                <img src="../../../../assets/images/google-review.png" alt="Google Customer Reviews"
                    class="review-badge">
            </div>
        </div>
    </div>
    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<!-- </section> -->
<div class="container">
    <!-- Fixed Sidebar with Buttons to Navigate to Sections -->
    <div class="sidebar">
        <button class="sidebar-btn" (click)="scrollToSection('Trademark')"
            [class.active]="activeSection === 'Trademark'">Trademark</button>

        <button class="sidebar-btn" (click)="scrollToSection('Copyright')"
            [class.active]="activeSection === 'Copyright'">Copyright
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Patent')"
            [class.active]="activeSection === 'Patent'">Patent

        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Infringement')"
            [class.active]="activeSection === 'Infringement'">Infringement
        </button>




    </div>

    <!-- Main Content Area with Anchored Sections -->
    <div class="content-wrapper">


        <div #Trademark class="content-section">

            <h1>Trademark: Definition and Importance</h1>

            <p>
                A trademark is a unique symbol, word, phrase, logo, design, or combination that identifies and
                distinguishes a business’s goods or services from others in the marketplace. It serves as a recognizable
                indicator of the source of products or services, ensuring that customers can easily identify and trust
                the brand. The value of a trademark lies in its ability to build brand loyalty and prevent consumer
                confusion. For example, logos like Apple’s bitten apple or Nike’s swoosh are trademarks that have become
                globally recognized symbols of quality and trust.
            </p>
            <p>
                Trademarks are valuable assets that contribute to the brand’s identity and can even become crucial for
                market positioning and competitive advantage. Registering a trademark provides businesses with exclusive
                rights to use their marks and protects against unauthorized usage by third parties.
            </p>

            <h2>The Trademark Registration Process</h2>
            <p>
                Registering a trademark is essential for businesses that want to protect their brand identity. Here’s a
                step-by-step breakdown of the process for registering a trademark:
            </p>

            <h3>Step 1: Trademark Search</h3>
            <p>
                Before applying for a trademark, it’s advisable to conduct a comprehensive search to check whether the
                desired trademark or a similar one is already registered. This helps avoid potential conflicts with
                existing trademarks and improves the likelihood of approval. In the U.S., this search can be conducted
                using the United States Patent and Trademark Office (USPTO) database, while in India, the Controller
                General of Patents, Designs, and Trade Marks provides an online search tool.
            </p>

            <h3>Step 2: Filing the Application</h3>
            <p>
                Once a suitable trademark is chosen, an application must be filed with the relevant trademark authority.
                The application includes details such as:
            </p>
            <ul>
                <li>The applicant’s name and address</li>
                <li>Representation of the trademark</li>
                <li>Description of goods or services associated with the trademark</li>
                <li>Class of goods or services</li>
            </ul>
            <p>
                Many countries follow the NICE Classification, a standardized classification system for categorizing
                goods and services for trademark registration purposes.
            </p>

            <h3>Step 3: Examination of the Application</h3>
            <p>
                After submission, the trademark application undergoes a thorough examination by the trademark office to
                determine its eligibility. The examiner checks for compliance with legal requirements, assesses the
                distinctiveness of the mark, and identifies any conflicting registered marks. The examiner may issue an
                office action outlining concerns or objections, which the applicant must address within a specified
                period.
            </p>

            <h3>Step 4: Publication for Opposition</h3>
            <p>
                If the application passes examination, the trademark is published in the official gazette or trademark
                journal for a period, typically between 30 to 90 days, during which third parties can oppose the
                registration. Opposition may arise if another party believes the new trademark is too similar to their
                existing mark, likely to confuse consumers, or violates established trademark rights. If no opposition
                is filed, the application proceeds to the next stage.
            </p>

            <h3>Step 5: Registration and Certificate Issuance</h3>
            <p>
                If the trademark application is successful and there’s no opposition (or opposition is resolved in favor
                of the applicant), the trademark is officially registered, and a certificate of registration is issued
                to the applicant. This certificate grants the applicant exclusive rights to use the trademark and
                provides legal recourse in the event of infringement.
            </p>

            <h3>Step 6: Renewal</h3>
            <p>
                A trademark registration is typically valid for ten years and can be renewed indefinitely in subsequent
                ten-year intervals. Failure to renew may result in the trademark lapsing, after which it becomes
                available for new applicants.
            </p>

            <h2>Benefits of Registering a Trademark for a Business</h2>

            <p>Registering a trademark provides several advantages to businesses:</p>

            <ul>
                <li><strong>Exclusive Rights</strong>: Trademark registration grants the owner exclusive rights to use
                    the mark for their goods or services, barring others from using similar marks in a manner that could
                    cause confusion.</li>
                <li><strong>Brand Protection</strong>: Trademark registration prevents other businesses from adopting
                    identical or confusingly similar marks, preserving the brand’s unique identity and customer trust.
                </li>
                <li><strong>Legal Recourse in Case of Infringement</strong>: Registered trademark owners can take legal
                    action against individuals or businesses that use their trademark without authorization. In many
                    jurisdictions, this includes rights to damages, injunctions, and, in severe cases, criminal
                    penalties for willful infringement.</li>
                <li><strong>Intangible Asset and Brand Value</strong>: A registered trademark is an intangible asset
                    that adds value to a business. A strong brand identity, often protected by a trademark, can increase
                    customer loyalty and attract investments or potential buyers.</li>
                <li><strong>Nationwide and Global Recognition</strong>: Trademark registration often provides
                    national-level protection, with options for international trademark registration available through
                    treaties like the Madrid Protocol. This enables businesses to expand globally while securing their
                    brand identity.</li>
                <li><strong>Consumer Recognition</strong>: Trademarks help build brand recognition, which promotes
                    customer loyalty and influences purchasing decisions. Customers are more likely to choose brands
                    they recognize and trust.</li>
            </ul>

            <h2>Trademark Law Provisions and Precedents</h2>

            <p>
                Trademark law is governed by national legislation, with many countries adhering to international
                treaties that provide a framework for standard practices in trademark protection. In the United States,
                the <strong>Lanham Act</strong> of 1946 is the primary law governing trademarks. In India, trademarks
                are regulated by the <strong>Trademarks Act of 1999</strong>.
            </p>

            <h3>Provisions in Trademark Law</h3>

            <ul>
                <li><strong>Eligibility</strong>: Defines the types of marks eligible for trademark protection,
                    typically requiring them to be distinctive and non-deceptive.</li>
                <li><strong>Application Procedure</strong>: Outlines the steps required for registration, including
                    examination, publication, and opposition procedures.</li>
                <li><strong>Rights of the Trademark Holder</strong>: Includes exclusive usage rights, renewal
                    requirements, and legal remedies in case of infringement.</li>
                <li><strong>Infringement</strong>: Sets the criteria for infringement, typically involving unauthorized
                    use of a registered trademark in a way that causes confusion or dilutes the brand.</li>
                <li><strong>Exceptions</strong>: Permits certain uses of trademarks, such as in comparative advertising
                    or non-commercial contexts, as long as they don’t cause confusion or harm the trademark’s
                    reputation.</li>
            </ul>

            <h3>Trademark Infringement</h3>

            <p>
                Trademark infringement occurs when a third party uses a registered trademark (or a confusingly similar
                one) without the owner's permission in a way that is likely to confuse consumers or harm the brand.
                Common forms of infringement include counterfeiting, passing off (selling products under another’s brand
                name), and cybersquatting (registering a domain name similar to a trademark with malicious intent).
            </p>

            <h3>Legal Precedents in Trademark Infringement Cases</h3>

            <p>
                Several landmark cases have shaped the interpretation of trademark infringement laws. Here are a few key
                examples:
            </p>
            <ul>
                <li><strong>Coca-Cola Co. v. Koke Co. of America (1920)</strong>: The Supreme Court ruled that using
                    “Koke” for soft drinks infringed upon Coca-Cola’s trademark, as it could mislead consumers into
                    thinking Koke was associated with Coca-Cola.</li>
                <li><strong>Qualitex Co. v. Jacobson Products Co. (1995)</strong>: The U.S. Supreme Court held that
                    colors can be trademarked if they serve to identify the source of a product, establishing that even
                    non-traditional marks can qualify as trademarks.</li>
                <li><strong>ITC Limited v. Punchgini, Inc. (2007)</strong>: The U.S. Second Circuit Court of Appeals
                    held that “famous” foreign marks that are not registered in the U.S. might not be protected under
                    U.S. trademark law unless actively used in U.S. commerce.</li>
                <li><strong>Cadbury UK Ltd v. Nestle SA (2013)</strong>: In the UK, Cadbury attempted to register its
                    distinctive purple color as a trademark, but the court ruled that Cadbury’s application was too
                    vague, underscoring the importance of specificity in trademark applications.</li>
            </ul>

            <h2>Conclusion</h2>
            <p>
                Trademark registration is a critical step for businesses to safeguard their brand identity, protect
                consumer trust, and maintain a competitive edge in the market. The registration process involves several
                steps, including a trademark search, filing an application, examination, publication, and renewal.
                Registered trademarks confer numerous benefits, including exclusive rights, legal recourse in cases of
                infringement, and increased brand recognition. Provisions in trademark law and precedents in
                infringement cases further strengthen the protection of registered trademarks, reinforcing their role as
                vital assets in modern business.
            </p>

        </div>

        <hr>

        <div #Copyright class="content-section">

            <h1>Copyright: Definition and Importance</h1>

            <p>
                Copyright is a legal right granted to the creator of original works, including literature, music, art,
                software, films, and other creative content. It provides the creator with exclusive rights to use,
                distribute, reproduce, and display their work for a specified period. Copyright is crucial for
                protecting intellectual property, ensuring that creators are rewarded for their work, and preventing
                unauthorized use or exploitation.
            </p>
            <p>
                Unlike patents or trademarks, copyright protection arises automatically upon the creation of a work,
                without the need for formal registration in many countries. However, registering copyright can provide
                additional benefits, especially when it comes to enforcing rights in court. Copyright plays a
                significant role in business, as it helps companies protect their creative assets, from marketing
                materials to proprietary software, ensuring that they maintain a competitive advantage and monetize
                their intellectual property.
            </p>

            <h2>The Copyright Registration Process</h2>

            <p>
                While copyright is automatically granted upon the creation of an original work, registering copyright
                can strengthen the legal standing of the work and is often a prerequisite for filing an infringement
                lawsuit. Here is a step-by-step outline of the copyright registration process:
            </p>

            <h3>Step 1: Determine Eligibility for Copyright Protection</h3>
            <p>
                Not all works qualify for copyright protection. To be eligible, the work must be:
            </p>
            <ul>
                <li><strong>Original</strong>: It should be a product of the creator’s own effort and should not be
                    copied from another source.</li>
                <li><strong>Fixed in a Tangible Medium</strong>: Copyright only protects works that can be recorded in
                    some form (e.g., written, recorded, or otherwise captured in a physical or digital format).</li>
            </ul>
            <p>
                Some examples of copyrightable works include:
            </p>
            <ul>
                <li>Literary works</li>
                <li>Music and lyrics</li>
                <li>Dramatic works and choreography</li>
                <li>Visual art, including paintings, drawings, and sculptures</li>
                <li>Films, television shows, and other audiovisual works</li>
                <li>Computer software</li>
                <li>Architectural works</li>
            </ul>

            <h3>Step 2: Gather Information and Complete the Application Form</h3>
            <p>
                To register a copyright, the creator needs to complete an application form with relevant details about
                the work, including:
            </p>
            <ul>
                <li>Title and description of the work</li>
                <li>Name, address, and other information about the author(s)</li>
                <li>Date of creation and publication (if applicable)</li>
                <li>Type of work and medium in which it is expressed</li>
            </ul>

            <h3>Step 3: Submit a Copy of the Work</h3>
            <p>
                In many cases, the copyright office requires a deposit (a copy of the work) as part of the application.
                For example, if you’re registering a book, you may need to provide a copy of the manuscript. If it’s a
                digital work, a copy may be submitted electronically. These deposits serve as proof of the work’s
                content and provide a basis for legal examination in case of a copyright dispute.
            </p>

            <h3>Step 4: Pay the Registration Fee and Submit the Application</h3>
            <p>
                The applicant must pay a registration fee, which varies by country and type of copyright. In the United
                States, the U.S. Copyright Office offers both online and paper applications, with different fees
                depending on the submission method and type of work.
            </p>

            <h3>Step 5: Processing and Issuance of Certificate</h3>
            <p>
                After submission, the copyright office processes the application, which may take several weeks or months
                depending on workload and application type. Once approved, a certificate of registration is issued,
                providing official documentation of the copyright and reinforcing the creator’s exclusive rights over
                the work.
            </p>

            <h2>Benefits of Copyright Registration for a Business</h2>

            <p>
                Copyright registration offers numerous advantages for businesses:
            </p>

            <ul>
                <li><strong>Legal Proof of Ownership</strong>: Registration serves as legal evidence of ownership,
                    making it easier to prove copyright in court and enforce rights against infringers.</li>
                <li><strong>Right to Sue for Infringement</strong>: In many jurisdictions, a registered copyright is
                    required before the owner can bring a lawsuit for infringement. Registering copyright strengthens
                    legal protection, providing a solid foundation for enforcing the copyright.</li>
                <li><strong>Eligibility for Statutory Damages and Attorney’s Fees</strong>: In cases of copyright
                    infringement, registered copyright holders may be eligible to claim statutory damages and attorney’s
                    fees. Statutory damages are often easier to secure than proving actual damages, as they provide
                    pre-set amounts for each instance of infringement.</li>
                <li><strong>Protection Against Unauthorized Use</strong>: Registering a copyright deters unauthorized
                    use of the work by third parties, as it makes it clear that the creator holds exclusive rights.
                    Copyright registration helps businesses control the use of their original content.</li>
                <li><strong>Monetization and Licensing Opportunities</strong>: Registered copyright provides a legal
                    basis for licensing agreements, allowing businesses to monetize their works by granting third
                    parties the right to use them in exchange for royalties or fees.</li>
                <li><strong>Enhanced Credibility and Brand Protection</strong>: Copyright registration adds legitimacy
                    to a business's intellectual property, supporting its reputation as an originator of quality
                    content.</li>
            </ul>

            <h2>Copyright Law Provisions and Precedents</h2>

            <p>
                Copyright law is governed by national laws, with the <strong>Berne Convention</strong> providing an
                international framework that standardizes copyright protection across member countries. In the United
                States, copyright is regulated by the <strong>Copyright Act of 1976</strong>, while in the UK, copyright
                protection is covered under the <strong>Copyright, Designs and Patents Act of 1988</strong>.
            </p>

            <h3>Provisions in Copyright Law</h3>

            <ul>
                <li><strong>Scope of Copyright Protection</strong>: Copyright law specifies the types of works eligible
                    for protection and the exclusive rights of copyright holders, including reproduction, distribution,
                    performance, display, and the creation of derivative works.</li>
                <li><strong>Duration of Copyright</strong>: In many countries, copyright lasts for the creator’s
                    lifetime plus an additional period (typically 70 years) after the creator’s death. For works created
                    by companies, the term may be fixed at a specific number of years after publication (e.g., 95 years
                    from publication in the U.S.).</li>
                <li><strong>Fair Use and Exceptions</strong>: Copyright law includes exceptions that allow limited use
                    of copyrighted works without permission, such as fair use in the U.S. This provision applies to
                    activities like criticism, commentary, news reporting, and educational use, provided they do not
                    harm the market for the original work.</li>
                <li><strong>Infringement and Penalties</strong>: Copyright infringement is the unauthorized use of
                    copyrighted material. Copyright law provides remedies for infringement, including damages,
                    injunctions, and, in some cases, criminal penalties for willful violations.</li>
                <li><strong>Transfer and Licensing</strong>: Copyright holders can transfer or license their rights to
                    others, allowing third parties to use the work under agreed terms.</li>
            </ul>

            <h3>Legal Precedents in Copyright Infringement Cases</h3>

            <p>
                Several landmark cases illustrate the principles of copyright law and have shaped its application over
                time:
            </p>

            <ul>
                <li><strong>Sony Corp. of America v. Universal City Studios, Inc. (1984)</strong>: In this case, the
                    U.S. Supreme Court ruled that making unauthorized copies of television programs for private use
                    (time-shifting) constituted fair use. This case set a precedent for interpreting fair use in the
                    context of new technologies.</li>
                <li><strong>Campbell v. Acuff-Rose Music, Inc. (1994)</strong>: This case involved a parody of Roy
                    Orbison’s song “Oh, Pretty Woman” by the rap group 2 Live Crew. The U.S. Supreme Court held that the
                    parody constituted fair use, emphasizing that transformative uses are more likely to be protected
                    under fair use.</li>
                <li><strong>Authors Guild v. Google, Inc. (2015)</strong>: This case involved Google’s digitization of
                    books for its search database. The court ruled that Google’s use of snippets of copyrighted books
                    was transformative and therefore constituted fair use, a landmark decision for the use of
                    copyrighted materials in digital formats.</li>
                <li><strong>Sega Enterprises Ltd. v. Accolade, Inc. (1992)</strong>: The Ninth Circuit ruled that
                    reverse engineering a video game for interoperability purposes was fair use. This case highlighted
                    the flexibility of fair use, particularly for software and technology industries.</li>
                <li><strong>Harper & Row Publishers, Inc. v. Nation Enterprises (1985)</strong>: In this case, the U.S.
                    Supreme Court ruled that publishing excerpts from an unpublished manuscript without permission did
                    not qualify as fair use, particularly because it impacted the market for the original work.</li>
            </ul>

            <h2>Conclusion</h2>
            <p>
                Copyright is a powerful tool for protecting creative works and fostering a thriving marketplace for
                intellectual property. By registering copyright, businesses can enjoy enhanced legal protection, enforce
                exclusive rights, and potentially secure compensation in cases of infringement. Copyright laws provide
                robust safeguards for original works, allowing creators and businesses to control their use, seek
                damages, and pursue legal recourse when necessary.
            </p>


        </div>

        <hr>

        <div #Patent class="content-section">

            <h1>Patent: Definition and Importance</h1>
            <p>A patent is a legal right granted to an inventor or assignee by a government authority, giving them
                exclusive rights to use, produce, and sell an invention for a limited period, typically 20 years. This
                protection covers inventions that are novel, non-obvious, and useful, often in fields like technology,
                pharmaceuticals, engineering, and manufacturing. Patents are crucial for fostering innovation as they
                provide inventors with an incentive to invest time and resources in developing new products or
                technologies, knowing they will have a monopoly over their inventions and the potential to profit from
                them.</p>
            <p>For businesses, patents serve as valuable assets. A well-protected invention can improve market
                positioning, generate revenue through licensing or sales, and deter competitors. By securing patents,
                businesses can protect their investments in research and development, making patents especially
                significant in industries where innovation is key to maintaining a competitive edge.</p>

            <h2>The Patent Registration Process</h2>
            <p>Securing a patent involves a detailed process that requires satisfying legal criteria and meeting
                specific procedural requirements. Here’s a breakdown of the process for obtaining a patent:</p>

            <h3>Step 1: Conduct a Patent Search</h3>
            <p>Before filing for a patent, it’s essential to conduct a thorough patent search to determine if a similar
                invention has already been patented. This search helps identify prior art, which includes any public
                documentation, patents, or publications related to the invention, ensuring that the invention is novel
                and has not been claimed by another inventor. Many countries have patent offices with databases
                accessible to the public, such as the United States Patent and Trademark Office (USPTO) and the European
                Patent Office (EPO).</p>

            <h3>Step 2: Determine the Type of Patent</h3>
            <p>There are different types of patents based on the nature of the invention:</p>
            <ul>
                <li><strong>Utility Patents</strong>: Cover new and useful processes, machines, articles of manufacture,
                    or compositions of matter.</li>
                <li><strong>Design Patents</strong>: Protect new, original, and ornamental designs of articles of
                    manufacture.</li>
                <li><strong>Plant Patents</strong>: Granted for new and distinct plant varieties that have been
                    asexually reproduced.</li>
            </ul>
            <p>Determining the type of patent required is essential, as each type has different requirements and scopes
                of protection.</p>

            <h3>Step 3: Prepare the Patent Application</h3>
            <p>Patent applications are complex documents that must include the following components:</p>
            <ul>
                <li><strong>Title and Abstract</strong>: The title and a brief description of the invention.</li>
                <li><strong>Background</strong>: Describes the existing field and any issues the invention addresses.
                </li>
                <li><strong>Summary</strong>: A high-level explanation of the invention.</li>
                <li><strong>Detailed Description</strong>: A comprehensive explanation of the invention, including how
                    it operates and the problems it solves.</li>
                <li><strong>Claims</strong>: The most critical part, as it defines the scope of the patent’s protection.
                    Claims must be clear, precise, and cover only what is novel.</li>
                <li><strong>Drawings (if applicable)</strong>: Diagrams, flowcharts, or illustrations that visually
                    depict the invention.</li>
            </ul>
            <p>Since claims determine the scope of protection, many inventors consult a patent attorney to draft claims
                accurately, as this part is highly technical and subject to legal scrutiny.</p>

            <h3>Step 4: File the Patent Application</h3>
            <p>The inventor submits the application to the relevant patent office, such as the USPTO or the World
                Intellectual Property Organization (WIPO) for international applications under the Patent Cooperation
                Treaty (PCT). An applicant may apply directly to foreign patent offices in each jurisdiction or use the
                PCT to file a single international application that preserves the option for protection in multiple
                countries.</p>
            <p>Patent filing fees vary depending on the country, the nature of the invention, and whether expedited
                processing is requested. For small or individual inventors, many patent offices offer reduced fees.</p>

            <h3>Step 5: Examination of the Patent Application</h3>
            <p>After submission, the application undergoes a formal examination by the patent office. The examiner
                reviews the application for compliance, including checking the novelty, non-obviousness, and industrial
                applicability of the invention. The examination process may involve correspondence between the examiner
                and the applicant to clarify claims or resolve objections. If the examiner raises concerns, the
                applicant may respond by amending claims, providing additional information, or challenging the
                examiner’s interpretation of prior art.</p>
            <p>If the patent application meets all legal requirements, the patent office grants the patent. Otherwise,
                the application may be rejected, with the option to appeal or refile.</p>

            <h3>Step 6: Publication and Issuance of the Patent</h3>
            <p>Once the patent is granted, it is published in the official patent journal or gazette, making it public.
                The patent owner receives a patent certificate, officially granting them exclusive rights to the
                invention.</p>

            <h3>Step 7: Maintenance Fees and Renewals</h3>
            <p>Most jurisdictions require patent holders to pay maintenance fees periodically to keep the patent active.
                Failure to pay these fees can result in the patent lapsing, at which point the invention enters the
                public domain, and anyone can use it without restriction.</p>

            <h2>Benefits of Patent Registration for a Business</h2>
            <p>Patent registration provides significant benefits to businesses:</p>
            <ul>
                <li><strong>Exclusive Rights</strong>: A patent grants the owner the exclusive right to make, use, sell,
                    and license the invention, preventing others from profiting from their work without permission.</li>
                <li><strong>Market Advantage</strong>: Patents help businesses secure a competitive edge by limiting
                    competitors’ access to innovative technologies and allowing the business to establish a strong
                    market presence.</li>
                <li><strong>Monetization and Licensing</strong>: Patents can be licensed to third parties in exchange
                    for royalties or sold, providing an additional revenue stream for businesses. Licensing can also
                    open up opportunities for collaboration and expansion.</li>
                <li><strong>Protection of R&D Investments</strong>: Patents protect investments in research and
                    development by preventing competitors from freely copying and commercializing inventions. This
                    protection is particularly valuable in industries like pharmaceuticals and technology, where R&D
                    costs are high.</li>
                <li><strong>Enhanced Valuation and Attractiveness to Investors</strong>: Patents are considered valuable
                    assets that increase a company’s market valuation. They demonstrate a commitment to innovation,
                    which can attract investors or partners.</li>
                <li><strong>Legal Recourse Against Infringement</strong>: Patent registration provides a legal basis for
                    enforcing exclusive rights and taking action against infringers. Businesses can seek damages,
                    injunctions, or settlements in cases of unauthorized use.</li>
            </ul>

            <h2>Patent Law Provisions and Precedents</h2>
            <p>Patent laws provide the framework for granting and enforcing patents and vary slightly across
                jurisdictions. In the United States, patents are governed by the <strong>Patent Act</strong> (Title 35
                of the U.S. Code), while the European Patent Convention governs patents in Europe.</p>

            <h3>Key Provisions in Patent Law</h3>
            <ul>
                <li><strong>Eligibility Requirements</strong>: Patent law defines criteria for patent eligibility,
                    requiring inventions to be novel, non-obvious, and useful. Abstract ideas, laws of nature, and
                    mathematical algorithms are typically excluded from patent protection.</li>
                <li><strong>Rights of the Patent Holder</strong>: The patent holder has exclusive rights to make, use,
                    sell, and license the patented invention. This includes the right to seek legal recourse in case of
                    infringement.</li>
                <li><strong>International Protection</strong>: Patents are territorial and must be registered in each
                    jurisdiction where protection is sought. The Patent Cooperation Treaty (PCT) simplifies this process
                    by allowing inventors to file a single international application.</li>
                <li><strong>Patent Duration</strong>: Most patents are valid for 20 years from the filing date, provided
                    that maintenance fees are paid. After expiration, the invention enters the public domain.</li>
                <li><strong>Infringement and Remedies</strong>: Patent infringement involves the unauthorized use,
                    production, or sale of a patented invention. Remedies for infringement include damages, injunctions,
                    and, in some cases, legal fees.</li>
            </ul>

            <h3>Landmark Patent Infringement Cases</h3>
            <ul>
                <li><strong>Diamond v. Chakrabarty (1980)</strong>: The U.S. Supreme Court ruled that a genetically
                    modified bacterium was patentable, establishing that living organisms could qualify for patent
                    protection if they are human-made inventions.</li>
                <li><strong>Amazon.com, Inc. v. Barnesandnoble.com, Inc. (2001)</strong>: Amazon sued Barnes & Noble for
                    infringing its "1-Click" checkout patent. The court initially granted Amazon a preliminary
                    injunction but later ruled that Barnes & Noble had successfully challenged the patent’s validity.
                    This case highlighted the scrutiny of software patents for non-obviousness.</li>
                <li><strong>Festo Corp. v. Shoketsu Kinzoku Kogyo Kabushiki Co. (2002)</strong>: The Supreme Court ruled
                    on the doctrine of equivalents, determining the limits of claim amendments in patent applications.
                    This case clarified the scope of patent claims and the limitations on extending patent coverage
                    beyond specific claims.</li>
                <li><strong>Mayo Collaborative Services v. Prometheus Laboratories, Inc. (2012)</strong>: The Supreme
                    Court held that a diagnostic process based on natural laws was not patentable, emphasizing that
                    patents cannot claim laws of nature or abstract ideas. This decision impacted the pharmaceutical and
                    biotech industries, influencing patent eligibility criteria.</li>
            </ul>
            <p>These cases underscore the principles of novelty, non-obviousness, and the careful delineation of patent
                claims. Courts tend to uphold patents when they represent genuine innovation and benefit to the public.
            </p>

            <h2>Conclusion</h2>
            <p>Patents play an essential role in fostering innovation and protecting intellectual property in
                competitive markets. The patent registration process involves detailed application requirements,
                examination, and, ultimately, legal enforcement. For businesses, patents are powerful assets that
                provide exclusive rights, support market dominance, and open monetization opportunities through
                licensing. Patent laws and significant legal precedents ensure that patents reward inventors for their
                contributions while balancing the public’s interest in technological progress and accessibility.</p>


        </div>

        <hr>


        <div #Infringement class="content-section">
            <h1>Infringement of Intellectual Property Rights (IPR): An Indian Perspective</h1>
            <p>Intellectual Property Rights (IPR) are critical in fostering innovation, creativity, and brand identity
                in India. IPR in India encompasses protections over patents, copyrights, and trademarks, safeguarding
                inventors, creators, and businesses from unauthorized use of their intellectual property. Infringement
                occurs when third parties engage in activities that violate the exclusive rights granted under IPR laws.
                Indian legislation, including the Patents Act of 1970, Copyright Act of 1957, and Trade Marks Act of
                1999, provide robust protections, with courts interpreting these laws through landmark judgments. This
                document examines each type of IPR infringement, its legal provisions in India, and relevant case
                precedents.</p>

            <h2>Patent Infringement</h2>
            <p>A patent provides an inventor with exclusive rights to manufacture, use, sell, or license an invention,
                typically lasting for 20 years. Patent infringement occurs when someone unlawfully makes, uses, or sells
                a patented invention without authorization. Indian patent law focuses on the protection of both product
                and process patents, especially in sectors like pharmaceuticals, engineering, and technology.</p>

            <h3>Relevant Provisions Under the Patents Act, 1970</h3>
            <ul>
                <li><strong>Section 48:</strong> Outlines the exclusive rights of a patent holder, granting them the
                    authority to prevent unauthorized use, sale, or production of their patented product or process.
                </li>
                <li><strong>Section 104:</strong> Specifies that patent infringement suits can only be filed in District
                    Courts, which have exclusive jurisdiction to hear these cases.</li>
                <li><strong>Sections 107-109:</strong> Define defenses and remedies available to defendants and
                    plaintiffs, respectively. Remedies for infringement include injunctions, damages, or accounts of
                    profits.</li>
            </ul>
            <p>India follows the Doctrine of Equivalents, which protects patent holders by allowing them to claim
                infringement even if an infringer uses minor variations of the patented invention to bypass direct
                copying.</p>

            <h3>Landmark Cases in Patent Infringement</h3>
            <ul>
                <li><strong>Novartis AG v. Union of India (2013):</strong> This landmark Supreme Court case revolved
                    around Novartis's application for a patent for its cancer drug, Glivec. The court rejected the
                    patent application under Section 3(d) of the Patents Act, which prevents the patenting of new forms
                    of known substances unless they significantly enhance efficacy. The case established a high
                    threshold for pharmaceutical patents, reinforcing India’s stance on affordable healthcare.</li>
                <li><strong>Roche v. Cipla (2009):</strong> In this case, Roche filed a lawsuit against Cipla, alleging
                    that Cipla’s cancer drug infringed on Roche’s patent for the drug Erlotinib. The Delhi High Court
                    ruled in favor of Cipla, citing the public interest in affordable drugs and the need for access to
                    life-saving medication. This case highlighted the balance Indian courts maintain between patent
                    rights and public health.</li>
                <li><strong>Merck Sharp & Dohme Corp. v. Glenmark Pharmaceuticals (2015):</strong> The Delhi High Court
                    ruled that Glenmark had infringed Merck’s patent for its diabetes drug, Januvia. The court granted
                    an injunction against Glenmark, reinforcing the patent holder’s rights under Section 48 of the
                    Patents Act and demonstrating the legal protection available to pharmaceutical patents in India.
                </li>
            </ul>

            <h2>Copyright Infringement</h2>
            <p>Copyright protects original works such as literature, music, art, film, and software, granting creators
                exclusive rights to reproduce, distribute, and display their works. Copyright infringement occurs when a
                third party uses copyrighted material without permission.</p>

            <h3>Relevant Provisions Under the Copyright Act, 1957</h3>
            <ul>
                <li><strong>Section 14:</strong> Defines the exclusive rights of copyright holders, including the rights
                    to reproduce, adapt, and distribute their work.</li>
                <li><strong>Section 51:</strong> Details what constitutes copyright infringement, including unauthorized
                    reproduction, distribution, or adaptation of a copyrighted work.</li>
                <li><strong>Section 55:</strong> Provides for civil remedies, including injunctions and damages, against
                    infringers.</li>
                <li><strong>Section 63:</strong> Outlines criminal liabilities for copyright infringement, which may
                    include fines and imprisonment.</li>
            </ul>
            <p>Fair dealing is an important doctrine under Indian copyright law, similar to fair use in other
                jurisdictions. This doctrine allows limited use of copyrighted material for purposes such as research,
                criticism, and education.</p>

            <h3>Landmark Cases in Copyright Infringement</h3>
            <ul>
                <li><strong>Eastern Book Company v. D.B. Modak (2008):</strong> This Supreme Court case involved
                    copyright claims over court judgments that Eastern Book Company had published. The court ruled that
                    while original judgments cannot be copyrighted, the publisher’s headnotes and formatting had
                    creative input, qualifying them for copyright protection. This case clarified the copyrightability
                    of derivative works in India.</li>
                <li><strong>Super Cassettes Industries v. MySpace Inc. (2011):</strong> Super Cassettes Industries
                    (T-Series) sued MySpace for hosting copyrighted music without permission. The Delhi High Court ruled
                    that MySpace was liable for copyright infringement since it failed to prevent unauthorized uploads.
                    This case underscored the importance of online platforms taking proactive measures against
                    infringement.</li>
                <li><strong>Indian Performing Right Society Ltd. v. Aditya Pandey (2011):</strong> This case involved
                    copyright infringement claims by the Indian Performing Right Society (IPRS) against mobile operators
                    for using copyrighted music as ringtones. The court ruled in favor of IPRS, holding that music
                    downloads and ringtones are covered under copyright law, which further extended copyright
                    enforcement to new digital formats.</li>
            </ul>

            <h2>Trademark Infringement</h2>
            <p>Trademarks protect brand names, logos, and symbols that distinguish goods or services in the marketplace.
                Trademark infringement occurs when an unauthorized party uses a mark that is identical or confusingly
                similar to a registered trademark, leading to consumer confusion and dilution of brand value.</p>

            <h3>Relevant Provisions Under the Trade Marks Act, 1999</h3>
            <ul>
                <li><strong>Section 29:</strong> Defines trademark infringement and provides the criteria for
                    determining whether a mark is deceptively similar or identical to a registered trademark.</li>
                <li><strong>Section 30:</strong> Details exceptions to infringement, including descriptive use and fair
                    use.</li>
                <li><strong>Section 134:</strong> Confers jurisdiction on District Courts to hear trademark infringement
                    cases.</li>
                <li><strong>Section 135:</strong> Provides remedies for trademark infringement, including injunctions,
                    damages, and accounts of profits.</li>
            </ul>
            <p>Indian law also recognizes the concept of passing off, which protects unregistered trademarks if they
                have acquired a reputation in the market.</p>

            <h3>Landmark Cases in Trademark Infringement</h3>
            <ul>
                <li><strong>Cadila Health Care Ltd. v. Cadila Pharmaceuticals Ltd. (2001):</strong> This Supreme Court
                    case established key criteria for assessing trademark infringement and the likelihood of confusion
                    between two similar marks. It emphasized factors like the nature of goods, the target audience, and
                    the resemblance between marks. The judgment established important principles for determining
                    deceptive similarity in trademark disputes.</li>
                <li><strong>Amritdhara Pharmacy v. Satya Deo Gupta (1963):</strong> In this case, the Supreme Court
                    ruled on deceptive similarity between “Amritdhara” and “Lakshmandhara,” holding that the phonetic
                    similarity created confusion among consumers. This case reinforced the importance of assessing
                    phonetic resemblance in trademark infringement cases.</li>
                <li><strong>Daimler Benz AG v. Hybo Hindustan (1994):</strong> Daimler Benz, the maker of Mercedes-Benz,
                    sued Hybo Hindustan for using the logo and name “Benz” on undergarments. The court held that
                    trademark dilution had occurred, as a luxury car brand’s association with inexpensive goods could
                    harm its reputation. This case highlighted the protection of well-known trademarks in India.</li>
                <li><strong>ITC Limited v. Philip Morris Products SA (2010):</strong> ITC filed a suit against Philip
                    Morris, alleging that the “Marlboro” packaging infringed upon ITC’s registered mark “Classic.” The
                    court ruled that even distinctive packaging can lead to trademark infringement. This case emphasized
                    the importance of trade dress in trademark protection.</li>
            </ul>

            <h2>Conclusion</h2>
            <p>India’s legal framework for IPR infringement across patents, copyrights, and trademarks provides
                comprehensive protections for intellectual property holders. Key provisions within the Patents Act,
                1970, Copyright Act, 1957, and Trade Marks Act, 1999, define the rights, liabilities, and enforcement
                mechanisms available to protect these assets. Through landmark judgments, Indian courts have further
                refined the interpretation of these laws, balancing the interests of intellectual property owners and
                the public. Courts have established important doctrines, including the Doctrine of Equivalents for
                patents, fair dealing for copyrights, and deceptive similarity for trademarks, providing robust
                protections for IPR holders in India.</p>

        </div>

        <hr>

    </div>
</div>