<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Solutions Details</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Solutions Details</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div> -->

<!-- <section class="services-details-area ptb-100"> -->
<div class="page-title-area">

    <div class="container">
        <div class="registration-container">
            <div class="text-section">
                <h1><span>
                        License</span> All-in-One Licensing Solutions</h1>
                <p>
                    At LegalSetup offers a streamlined "License as a Service" platform to meet essential business
                    registration needs, including GST, MSME, 80 IAC Tax Exemption, Importer Exporter Code, FSSAI, Shop
                    and Establishments, ESI, TAN, PAN, PF, and Drug License registrations. With expert guidance and a
                    commitment to efficiency, we make regulatory compliance simple and stress-free. Let us handle your
                    licensing needs, so you can focus on driving your business forward.
                </p>
                <div class="stats">
                    <div>
                        <h2>1000+</h2>
                        <p>Happy Customers</p>
                    </div>
                    <div>
                        <h2>20 +</h2>
                        <p>CA & Lawyers</p>
                    </div>
                    <div>
                        <h2>500 +</h2>
                        <p>Filings Every Month</p>
                    </div>
                </div>

                <div class="buttons">
                    <a href="tel:+91 9911566199" target="_blank"><button class="btn-schedule">Schedule Free
                            Consultation</button></a>
                    <button routerLink="/about" class="btn-info">See How It Works</button>
                </div>
            </div>
            <div class="image-section">
                <img src="../../../assets/images/license.jpg" alt="Certificate">
                <img src="../../../../assets/images/google-review.png" alt="Google Customer Reviews"
                    class="review-badge">
            </div>
        </div>
    </div>
    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<!-- </section> -->

<div class="container">
    <!-- Fixed Sidebar with Buttons to Navigate to Sections -->
    <div class="sidebar">
        <button class="sidebar-btn" (click)="scrollToSection('GSTRegistration')"
            [class.active]="activeSection === 'GSTRegistration'">GST Registration</button>

        <button class="sidebar-btn" (click)="scrollToSection('MSME')" [class.active]="activeSection === 'MSME'">MSME
            Registration
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('IAC')" [class.active]="activeSection === 'IAC'">80 IAC Tax
            Exemption


        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Importer')"
            [class.active]="activeSection === 'Importer'">Importer Exporter Code Registration

        </button>

        <button class="sidebar-btn" (click)="scrollToSection('FSSAI')" [class.active]="activeSection === 'FSSAI'">FSSAI
            Registration
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Shop')" [class.active]="activeSection === 'Shop'">Shop And
            Establishments Registration
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('ESI')" [class.active]="activeSection === 'ESI'">ESI
            Registration
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('TAN')" [class.active]="activeSection === 'TAN'">TAN
            Registration
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('PAN')" [class.active]="activeSection === 'PAN'">PAN
            Registration
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('PF')" [class.active]="activeSection === 'PF'">PF
            Registration
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Drug')" [class.active]="activeSection === 'Drug'">Drug
            License
        </button>


    </div>

    <!-- Main Content Area with Anchored Sections -->
    <div class="content-wrapper">

        <h1>Business Registrations in India</h1>
        <p>In India, various registrations are mandated by law for businesses to operate legally and avail themselves of
            benefits offered by the government. Each registration serves a specific purpose and is governed by distinct
            legal provisions. Below is a detailed examination of key registrations:</p>


        <div #GSTRegistration class="content-section">

            <h1>1. GST Registration</h1>

            <h3>Need</h3>
            <p>Goods and Services Tax (GST) registration is essential for businesses whose turnover exceeds the
                prescribed threshold limit. It enables the business to collect tax on behalf of the government and
                allows input tax credit.</p>

            <h3>Benefits</h3>
            <ul>
                <li>Legal recognition and compliance.</li>
                <li>Availment of input tax credits.</li>
                <li>Enhanced credibility among suppliers and customers.</li>
            </ul>

            <h3>Legal Mandates</h3>
            <p>As per the Goods and Services Tax Act, 2017:</p>
            <ul>
                <li><strong>Section 22:</strong> Mandates registration for businesses exceeding the turnover threshold.
                </li>
                <li><strong>Section 25:</strong> Outlines the process of registration.</li>
            </ul>

            <h3>Required Documents</h3>
            <ul>
                <li>PAN of the business.</li>
                <li>Proof of business registration.</li>
                <li>Identity and address proof of the promoters.</li>
                <li>Bank account statement.</li>
            </ul>

            <h3>Process of Registration</h3>
            <ol>
                <li>Visit the GST portal.</li>
                <li>Fill the application form.</li>
                <li>Submit the required documents.</li>
                <li>Receive an Application Reference Number (ARN).</li>
                <li>Obtain GSTIN upon approval.</li>
            </ol>

        </div>

        <hr>

        <div #MSME class="content-section">


            <h1>2. MSME Registration</h1>

            <h2>Need</h2>
            <p>
                Micro, Small, and Medium Enterprises (MSMEs) are crucial for the Indian economy. MSME registration
                provides access to government schemes, funding, and incentives.
            </p>

            <h2>Benefits</h2>
            <ul>
                <li>Eligibility for subsidies and schemes.</li>
                <li>Easier access to loans.</li>
                <li>Preference in government procurement.</li>
            </ul>

            <h2>Legal Mandates</h2>
            <p>As per the Micro, Small and Medium Enterprises Development Act, 2006:</p>
            <ul>
                <li><strong>Section 7</strong>: Defines MSME classification.</li>
                <li><strong>Section 8</strong>: Details the registration process.</li>
            </ul>

            <h2>Required Documents</h2>
            <ul>
                <li>Aadhar number of the applicant.</li>
                <li>Business address proof.</li>
                <li>Bank account statement.</li>
                <li>Partnership deed or incorporation certificate (if applicable).</li>
            </ul>

            <h2>Process of Registration</h2>
            <ol>
                <li>Visit the Udyam Registration portal.</li>
                <li>Fill the application form.</li>
                <li>Submit required documents.</li>
                <li>Receive the MSME certificate upon approval.</li>
            </ol>

        </div>

        <hr>

        <div #IAC class="content-section">


            <h1>3. 80 IAC Tax Exemption</h1>

            <h2>Need</h2>
            <p>Section 80-IAC provides tax exemptions for eligible startups. It promotes entrepreneurship by allowing
                deductions from taxable income.</p>

            <h2>Benefits</h2>
            <ul>
                <li>Tax holidays for three consecutive years.</li>
                <li>Encouragement for investment in startups.</li>
            </ul>

            <h2>Legal Mandates</h2>
            <p>As per the Income Tax Act, 1961:</p>
            <ul>
                <li><strong>Section 80-IAC:</strong> Outlines eligibility and benefits.</li>
            </ul>

            <h2>Required Documents</h2>
            <ul>
                <li>Certificate of incorporation.</li>
                <li>Business plan.</li>
                <li>Proof of funding.</li>
            </ul>

            <h2>Process of Registration</h2>
            <ol>
                <li>Apply online through the income tax e-filing portal.</li>
                <li>Submit necessary documents.</li>
                <li>Receive approval and tax exemption certificate.</li>
            </ol>

        </div>

        <hr>

        <div #Importer class="content-section">

            <h1>4. Importer Exporter Code (IEC) Registration</h1>

            <h2>Need</h2>
            <p>Importer Exporter Code (IEC) registration is mandatory for businesses engaged in import or export
                activities.</p>

            <h2>Benefits</h2>
            <ul>
                <li>Legal authorization for international trade.</li>
                <li>Easier access to foreign markets.</li>
            </ul>

            <h2>Legal Mandates</h2>
            <p>As per the Foreign Trade (Development and Regulation) Act, 1992:</p>
            <ul>
                <li><strong>Section 7:</strong> Mandates IEC for import/export activities.</li>
            </ul>

            <h2>Required Documents</h2>
            <ul>
                <li>PAN of the business.</li>
                <li>Proof of business registration.</li>
                <li>Bank account details.</li>
            </ul>

            <h2>Process of Registration</h2>
            <ol>
                <li>Apply online at the DGFT website.</li>
                <li>Submit required documents.</li>
                <li>Receive IEC upon approval.</li>
            </ol>
        </div>

        <hr>


        <div #FSSAI class="content-section">

            <h1>5. FSSAI Registration</h1>

            <div class="section">
                <h2>Need</h2>
                <p>The Food Safety and Standards Authority of India (FSSAI) registration is mandatory for food-related
                    businesses to ensure food safety and hygiene.</p>
            </div>

            <div class="section">
                <h2>Benefits</h2>
                <ul>
                    <li>Legal compliance for food businesses.</li>
                    <li>Enhanced consumer trust.</li>
                </ul>
            </div>

            <div class="section">
                <h2>Legal Mandates</h2>
                <p>As per the Food Safety and Standards Act, 2006:</p>
                <ul>
                    <li><strong>Section 31</strong>: Requires food businesses to obtain a license.</li>
                </ul>
            </div>

            <div class="section">
                <h2>Required Documents</h2>
                <ul>
                    <li>PAN of the business.</li>
                    <li>Address proof.</li>
                    <li>Food safety management plan.</li>
                </ul>
            </div>

            <div class="section">
                <h2>Process of Registration</h2>
                <ol>
                    <li>Visit the <a href="https://www.fssai.gov.in/" target="_blank">FSSAI website</a>.</li>
                    <li>Fill the application form.</li>
                    <li>Submit necessary documents.</li>
                    <li>Receive FSSAI registration certificate upon approval.</li>
                </ol>
            </div>

        </div>

        <hr>


        <div #Shop class="content-section">

            <h1>6. Shops and Establishments Registration Guide</h1>

            <section>
                <h2>Need for Registration</h2>
                <p>Registration under the Shops and Establishments Act is mandatory for most businesses, as it helps
                    ensure compliance with local labor laws, promoting a fair and lawful working environment.</p>
            </section>

            <section>
                <h2>Benefits of Registration</h2>
                <ul>
                    <li><strong>Legal Recognition</strong>: Provides formal status to the business, necessary for legal
                        transactions and credibility.</li>
                    <li><strong>Employee Rights Protection</strong>: Safeguards employee rights related to working
                        hours, wages, leave, and other conditions of employment.</li>
                </ul>
            </section>

            <section>
                <h2>Legal Mandates</h2>
                <p>According to the Shops and Establishments Act, 1953 (specific regulations may vary by state):</p>
                <ul>
                    <li><strong>Mandatory Registration</strong>: All commercial establishments, including shops,
                        offices, and other businesses, must register under this Act.</li>
                </ul>
            </section>

            <section>
                <h2>Required Documents</h2>
                <ul>
                    <li><strong>PAN Card</strong> of the business entity.</li>
                    <li><strong>Address Proof</strong>: Rental agreement, utility bill, or other documents verifying the
                        business location.</li>
                    <li><strong>Employee Details</strong>: Information on the number and roles of employees.</li>
                </ul>
            </section>

            <section>
                <h2>Process of Registration</h2>
                <ol>
                    <li><strong>Approach the Local Labor Office</strong>: Visit the office responsible for labor and
                        establishment registrations or check for online registration options if available in your state.
                    </li>
                    <li><strong>Fill Out the Application Form</strong>: Complete the registration form with accurate
                        business details.</li>
                    <li><strong>Submit Required Documents</strong>: Attach PAN, address proof, and employee details
                        along with the form.</li>
                    <li><strong>Obtain the Registration Certificate</strong>: Upon approval, receive the registration
                        certificate, which serves as legal evidence of your business's compliance.</li>
                </ol>
            </section>

        </div>

        <hr>



        <div #ESI class="content-section">

            <h1>7. ESI Registration</h1>

            <h2>Need</h2>
            <p>The Employees' State Insurance (ESI) scheme provides social security and health insurance benefits to
                employees.</p>

            <h2>Benefits</h2>
            <ul>
                <li>Health benefits for employees.</li>
                <li>Maternity benefits and sickness leave.</li>
            </ul>

            <h2>Legal Mandates</h2>
            <p>As per the Employees' State Insurance Act, 1948:</p>
            <ul>
                <li><strong>Section 2:</strong> Requires registration of establishments with specific employee strength.
                </li>
            </ul>

            <h2>Required Documents</h2>
            <ul>
                <li>PAN of the business.</li>
                <li>Address proof.</li>
                <li>Details of employees.</li>
            </ul>

            <h2>Process of Registration</h2>
            <ol>
                <li>Apply online at the ESI website.</li>
                <li>Fill the application form.</li>
                <li>Submit required documents.</li>
                <li>Obtain ESI registration.</li>
            </ol>

        </div>

        <hr>



        <div #TAN class="content-section">

            <h1>8. TAN Registration</h1>

            <h2>Need</h2>
            <p>Tax Deduction and Collection Account Number (TAN) is required for businesses that deduct or collect tax
                at source.</p>

            <h2>Benefits</h2>
            <ul>
                <li>Legal compliance for tax deduction.</li>
                <li>Avoid penalties for non-compliance.</li>
            </ul>

            <h2>Legal Mandates</h2>
            <p>As per the Income Tax Act, 1961:</p>
            <ul>
                <li><strong>Section 203A:</strong> Mandates TAN for tax-deducting entities.</li>
            </ul>

            <h2>Required Documents</h2>
            <ul>
                <li>PAN of the business.</li>
                <li>Application form (49B).</li>
            </ul>

            <h2>Process of Registration</h2>
            <ol>
                <li>Apply online on the NSDL website.</li>
                <li>Fill the TAN application form.</li>
                <li>Submit required documents.</li>
                <li>Receive TAN upon approval.</li>
            </ol>
        </div>

        <hr>



        <div #PAN class="content-section">

            <h1>9. PAN Registration</h1>

            <h2>Need</h2>
            <p>Permanent Account Number (PAN) is essential for tax identification and is required for all financial
                transactions.</p>

            <h2>Benefits</h2>
            <ul>
                <li>Legal recognition for financial transactions.</li>
                <li>Necessary for filing income tax returns.</li>
            </ul>

            <h2>Legal Mandates</h2>
            <p>As per the Income Tax Act, 1961:</p>
            <ul>
                <li><strong>Section 139A:</strong> Mandates PAN for all taxpayers.</li>
            </ul>

            <h2>Required Documents</h2>
            <ul>
                <li>Proof of identity (Aadhar, Passport).</li>
                <li>Proof of address.</li>
                <li>Business registration documents.</li>
            </ul>

            <h2>Process of Registration</h2>
            <ol>
                <li>Apply online on the NSDL or UTIITSL website.</li>
                <li>Fill the PAN application form.</li>
                <li>Submit required documents.</li>
                <li>Receive PAN card upon approval.</li>
            </ol>

        </div>

        <hr>


        <div #PF class="content-section">

            <h1>10. PF Registration</h1>

            <h2>Need</h2>
            <p>Provident Fund (PF) registration is necessary for organizations employing a specific number of employees,
                ensuring social security benefits.</p>

            <h2>Benefits</h2>
            <ul>
                <li>Retirement savings for employees.</li>
                <li>Tax benefits for employers.</li>
            </ul>

            <h2>Legal Mandates</h2>
            <p>As per the Employees' Provident Funds and Miscellaneous Provisions Act, 1952:</p>
            <ul>
                <li>Section 1: Mandates registration for establishments with 20 or more employees.</li>
            </ul>

            <h2>Required Documents</h2>
            <ul>
                <li>PAN of the business.</li>
                <li>Business registration certificate.</li>
                <li>Employee details.</li>
            </ul>

            <h2>Process of Registration</h2>
            <ol>
                <li>Apply online on the EPFO website.</li>
                <li>Fill the application form.</li>
                <li>Submit required documents.</li>
                <li>Receive PF registration number.</li>
            </ol>

        </div>

        <hr>


        <div #Drug class="content-section">
            <h1>11. Drug License Information</h1>
            <h2>Need</h2>
            <p>A drug license is required for businesses involved in the manufacture, sale, or distribution of drugs and
                pharmaceuticals.</p>

            <h2>Benefits</h2>
            <ul>
                <li>Legal compliance for pharmaceutical operations.</li>
                <li>Consumer trust in the quality of products.</li>
            </ul>

            <h2>Legal Mandates</h2>
            <p>As per the Drugs and Cosmetics Act, 1940:</p>
            <ul>
                <li>Section 18: Requires licenses for the manufacture and sale of drugs.</li>
            </ul>

            <h2>Required Documents</h2>
            <ul>
                <li>Application form.</li>
                <li>Identity proof.</li>
                <li>Premises details.</li>
                <li>Manufacturing process.</li>
            </ul>

            <h2>Process of Registration</h2>
            <ol>
                <li>Apply online at the Central Drugs Standard Control Organization (CDSCO) website.</li>
                <li>Fill the application form.</li>
                <li>Submit required documents.</li>
                <li>Obtain the drug license upon inspection and approval.</li>
            </ol>
        </div>

        <hr>

        <h2>Conclusion</h2>
        <p>
            Each registration plays a pivotal role in ensuring that businesses operate within the legal framework established by Indian law. Understanding the requirements, benefits, and processes associated with these registrations not only aids in compliance but also enhances business credibility and operational efficiency. Entrepreneurs must navigate these processes diligently to ensure a robust foundation for their ventures.
        </p>
    </div>
</div>