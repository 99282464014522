<app-navbar></app-navbar>
<router-outlet></router-outlet>
<app-footer></app-footer>
<ngx-scrolltop></ngx-scrolltop>

<a href="https://wa.me/+919911566199
" class="floating" target="_blank">
    <i class="bx bxl-whatsapp"></i>
</a>

<a href="https://www.google.com/maps?ll=28.514217,77.165447&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=15508234987830063501"
    target="_blank" class="floating1">
    <i class="bx bx-map"></i>
</a>