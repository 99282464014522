<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>About</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">About</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <h2>Your Virtual Legal Department</h2>
                        <p>We are in the business of ensuring legal and financial inclusion by simplifying legal
                            services and solutions to the problems Entrepreneurs face everyday on their journey. The
                            need for legal documentation in everyday life and business cannot be overemphasized. Since
                            1992, we have been bridging the legal gap and fostering seamless business transactions
                            across India.</p>
                        <p>Together with our team of energetic, forward thinking, young Professionals, LegalSetup is
                            building a roadmap to the future of how legal services should be offered and we are fixated
                            on delivering on this promise. We aim to deliver value by staying abreast with current and
                            all developing technologies and in order to be a distinguished industry contributor.</p>
                    </div>

                    <a routerLink="/about" class="box-btn">Know More</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="../../../../assets/images/home1.JPG" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="technology-area ptb-100 bg-color">
    <div class="container">
        <div class="row align-items-center choose-c">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="../../../../assets/images/about3.JPG" alt="choose" />

                    <div class="technology-video">
                        <a href="https://www.youtube.com/@legalsetup" class="video-btn popup-youtube"><i
                                class='bx bx-play'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="technology-content">
                    <h2>Empowering Entrepreneurs with Legal Clarity
                    </h2>
                    <p>At LegalSetup, we understand that navigating the legal landscape can be daunting, especially for
                        entrepreneurs focused on building and growing their businesses. That's why we provide tailored,
                        accessible solutions that address the unique challenges entrepreneurs face. Our mission is to
                        empower clients with a strong legal foundation, so they can confidently make decisions, pursue
                        opportunities, and drive innovation without legal hurdles. Through a blend of expertise,
                        technology, and dedication, we ensure our clients' legal needs are met efficiently and
                        transparently, enabling them to focus on their core objectives while we handle the complexities
                        of the legal realm.
                    </p>
                    <a routerLink="/contact" class="box-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="feature-area bg-color ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Features</span>
                        <h2> Why You Should Trust Us?</h2>
                        <p>Our primary focus is towards assisting the clients with navigating the legal and the
                            regulatory requirements that are related to business. With this purpose, our primary goal is
                            to become the partner of choice for the assistance related to company registration and
                            business management in India.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i>
                            Recognized by Govt. of India</li>
                        <li><i class="flaticon-correct"></i>
                            Trained & Professional Experts</li>
                        <li><i class="flaticon-correct"></i>
                            Affordable </li>
                        <li><i class="flaticon-correct"></i>
                            Data Security & Trust</li>
                        <li><i class="flaticon-correct"></i>
                            Commitment to Accuracy</li>
                        <li><i class="flaticon-correct"></i>
                            10000+ Happy Customers</li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>


