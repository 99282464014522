import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-documentation',
  templateUrl: './documentation.component.html',
  styleUrls: ['./documentation.component.scss']
})
export class DocumentationComponent {
  activeSection = 'overview';
  constructor() { }

  ngOnInit(): void {
  }
  // ViewChild references to each section
  @ViewChild('Business') Business!: ElementRef;
  @ViewChild('Personal') Personal!: ElementRef;
  @ViewChild('Estate') Estate!: ElementRef;
  @ViewChild('Notices') Notices!: ElementRef;
  @ViewChild('HR') HR!: ElementRef;

  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = this[sectionId];
    if (section) {
      section.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
