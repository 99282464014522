<footer class="footer-area pt-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="content">
                    <div class="logo">
                        <a routerLink="/"><img src="../../../../assets/images/logo3noback.png" alt="logo" /></a>
                    </div>
                    <p>Formation, Compliance And Legal Solutions</p>

                    <!-- <div class="subscribe">
                        <h4>Join Newsletter</h4>
                        <form class="newsletter-form">
                            <input type="email" id="emails" class="form-control" placeholder="Your Email" name="EMAIL" required autocomplete="off">
                            <button class="box-btn" type="submit">Subscribe</button>
                        </form>
                    </div> -->

                    <ul class="social">
                        <li><a href="https://www.linkedin.com/in/legal-setup-7297932b5/" target="_blank"><i
                                    class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/legalsetupgroup/profilecard/?igsh=MXd0c2d2azc4MG90MQ== "
                                target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="https://www.youtube.com/@legalsetup" target="_blank"> <i
                                    class="bx bxl-youtube"></i></a></li>
                        <li><a href="https://www.google.com/maps?ll=28.514217,77.165447&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=15508234987830063501"
                                target="_blank"> <i class="bx bx-location-plus"></i></a></li>

                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-md-6">
                <div class="content ml-15">
                    <h3>Our Service</h3>

                    <ul class="footer-list">
                        <li><a routerLink="startup">Startup</a></li>
                        <li><a routerLink="license"> License</a></li>
                        <li><a routerLink="complaince-tax">Complaince & Tax</a></li>
                        <li><a routerLink="documentation">Documentation </a></li>
                        <li><a routerLink="trademark">Trademark & IPR</a></li>
                        <li><a routerLink="attestation">Attestation Service</a></li>
                    </ul>
                </div>
            </div>


            <div class="col-lg-3 col-md-6">
                <div class="content contacts">
                    <h3 class="ml-40">Contact</h3>

                    <ul class="footer-list foot-social">
                        <li><a href="tel:+91 9911566199"><i class="bx bx-mobile-alt"></i>+91 9911566199</a></li>
                        <!-- <li><a href="tel:+1975456789"><i class="bx bx-phone"></i> +1 975 456 789</a></li> -->
                        <li><a href="mailto:legalsetupgroup@gmail.com"><i class="bx bxs-envelope"></i>
                                legalsetupgroup@gmail.com</a></li>
                        <!-- <li><a href="mailto:support@aiva.com"><i class="bx bxs-envelope"></i> support@aiva.com</a></li> -->
                        <li><i class="bx bxs-map"></i> Unit 3015, Gate no 2, Ambience Tower LN, Sector-A Vasant Kunj,
                            New Delhi-70</li>
                    </ul>
                </div>
            </div>


            <div class="col-lg-4 col-md-6">
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7011.696235354535!2d77.165447!3d28.514217!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1e6c10000027%3A0xd738518e26e5318d!2sAmbience%20Tower!5e0!3m2!1sen!2sin!4v1730538815733!5m2!1sen!2sin"
                    width="400" height="300" style="border:0;" allowfullscreen="" loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
    </div>

    <div class="copy-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-6">
                    <ul class="menu">
                        <li><a routerLink="/">Home</a></li>
                        <li><a routerLink="/about">About</a></li>
                        <li><a routerLink="/solutions">Services</a></li>
                        <!-- <li><a routerLink="/gallery">Gallery</a></li>
                        <li><a routerLink="/blog">Blog</a></li> -->
                        <li><a routerLink="/contact">Contact</a></li>
                    </ul>
                </div>

                <div class="col-lg-6">
                    <p>© All Right Reserved<a href="/" target="_blank">Legal Setup</a></p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top">
    <i class='bx bx-chevrons-up'></i>
    <i class='bx bx-chevrons-up'></i>
</div>