import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-attestation',
  templateUrl: './attestation.component.html',
  styleUrls: ['./attestation.component.scss']
})
export class AttestationComponent {

  activeSection = 'overview';
  constructor() { }

  ngOnInit(): void {
  }
  // ViewChild references to each section
  @ViewChild('Apostille') Apostille!: ElementRef;
  @ViewChild('Embassy') Embassy!: ElementRef;
  @ViewChild('Legalization') Legalization!: ElementRef;
  @ViewChild('Commerce') Commerce!: ElementRef;
  @ViewChild('Translation') Translation!: ElementRef;
  @ViewChild('HRD') HRD!: ElementRef;
  @ViewChild('FRRO') FRRO!: ElementRef;


  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = this[sectionId];
    if (section) {
      section.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}