<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Solutions Details</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Solutions Details</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div> -->

<!-- <section class="services-details-area ptb-100"> -->
<div class="page-title-area">

    <div class="container">
        <div class="registration-container">
            <div class="text-section">
                <h1><span>
                        Documentation</span> Docs Made Easy

                </h1>
                <p>
                    LegalSetup provides comprehensive documentation services tailored to your needs. Our offerings
                    include Business Contracts to safeguard your agreements, Personal & Family documents for life's
                    important milestones, and Real Estate paperwork for smooth property transactions. We also assist
                    with drafting Notices and establishing robust HR Policies to ensure compliance and clarity in your
                    organization. Let us help you create the essential documents that support your personal and
                    professional endeavors.
                </p>
                <div class="stats">
                    <div>
                        <h2>1000+</h2>
                        <p>Happy Customers</p>
                    </div>
                    <div>
                        <h2>20 +</h2>
                        <p>CA & Lawyers</p>
                    </div>
                    <div>
                        <h2>500 +</h2>
                        <p>Filings Every Month</p>
                    </div>
                </div>

                <div class="buttons">
                    <a href="tel:+91 9911566199" target="_blank"><button class="btn-schedule">Schedule Free
                            Consultation</button></a>
                    <button routerLink="/about" class="btn-info">See How It Works</button>
                </div>
            </div>
            <div class="image-section">
                <img src="../../../assets/images/business_1.jpg" alt="Certificate">
                <img src="../../../../assets/images/google-review.png" alt="Google Customer Reviews"
                    class="review-badge">
            </div>
        </div>
    </div>
    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<!-- </section> -->
<div class="container">
    <!-- Fixed Sidebar with Buttons to Navigate to Sections -->
    <div class="sidebar">
        <button class="sidebar-btn" (click)="scrollToSection('Business')"
            [class.active]="activeSection === 'Business'">Business Contracts</button>

        <button class="sidebar-btn" (click)="scrollToSection('Personal')"
            [class.active]="activeSection === 'Personal'">Documents of HUF
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Estate')" [class.active]="activeSection === 'Estate'">Real
            Estate

        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Notices')"
            [class.active]="activeSection === 'Notices'">Notices
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('HR')" [class.active]="activeSection === 'HR'">HR Policies
        </button>

       


    </div>

    <!-- Main Content Area with Anchored Sections -->
    <div class="content-wrapper">



        <div #Business class="content-section">

            <h1>Business Contracts</h1>
            <section id="business-contracts">
                <h2>Need for Compliance</h2>
                <p>Business contracts are crucial for defining the rights and responsibilities of each party in
                    commercial transactions, safeguarding interests, and reducing legal risks. Properly drafted and
                    legally binding contracts help maintain smooth business operations and establish accountability.</p>

                <h2>Relevant Legal Provisions</h2>
                <h3>Indian Contract Act, 1872</h3>
                <p>Section 10 – Specifies the essential elements for a contract to be considered valid (free consent,
                    lawful consideration, etc.).</p>
                <p>Section 23 – Deals with lawful consideration and objects, outlining which agreements are void.</p>

                <h3>Stamp Act, 1899</h3>
                <p>Provides requirements for stamping business contracts based on the nature of the transaction to make
                    them admissible in court.</p>

                <h2>Consequences of Non-Compliance</h2>
                <ul>
                    <li>Contracts may be declared void or unenforceable if they lack essential elements, as per Section
                        10 of the Contract Act.</li>
                    <li>Penalties may be imposed under the Stamp Act for unstamped agreements, making the contract
                        invalid in legal disputes.</li>
                    <li>Loss of legal standing to claim damages or enforce rights in case of a breach.</li>
                </ul>

                <h2>Compliance Process and Necessary Documents</h2>
                <ul>
                    <li><strong>Contract Drafting:</strong> Involves defining terms and conditions, scope of work,
                        payment terms, dispute resolution mechanisms, and termination clauses.</li>
                    <li><strong>Stamping & Registration:</strong> Stamp duty must be paid as per state-specific rates
                        under the Stamp Act, and high-value contracts may need to be registered.</li>
                    <li><strong>Execution:</strong> The contract should be signed by authorized representatives from
                        each party to establish mutual consent.</li>
                </ul>
            </section>

        </div>

        <hr>

        <div #Personal class="content-section">

            <h1>Documents of HUF (Hindu Undivided Family)</h1>
            <section id="documents-huf">
                <h2>Need for Compliance</h2>
                <p>A Hindu Undivided Family (HUF) is recognized under Indian law as a distinct entity for tax purposes.
                    Compliance in establishing and maintaining HUF documentation is necessary to legitimize its legal
                    status and facilitate property and income management.</p>

                <h2>Relevant Legal Provisions</h2>
                <h3>Hindu Succession Act, 1956</h3>
                <p>Governs inheritance and property rights within an HUF, defining the rights of coparceners (family
                    members with a stake in the HUF property).</p>

                <h3>Income Tax Act, 1961</h3>
                <p>Section 2(31) – Defines an HUF as a taxable entity.</p>
                <p>Section 10 – Specifies exemptions and deductions available for an HUF’s income.</p>

                <h2>Consequences of Non-Compliance</h2>
                <ul>
                    <li>Loss of tax benefits and exemptions available to HUFs under the Income Tax Act.</li>
                    <li>Disputes regarding inheritance, as HUF status legitimizes shared property.</li>
                    <li>Penalties or additional taxes if income is misrepresented.</li>
                </ul>

                <h2>Compliance Process and Necessary Documents</h2>
                <ul>
                    <li><strong>HUF Formation:</strong> Requires a declaration of the HUF, signed by the Karta (head of
                        the HUF), and other members, usually on stamp paper.</li>
                    <li><strong>PAN Card Application:</strong> An HUF must have a PAN card, treated as a separate entity
                        by the Income Tax Department.</li>
                    <li><strong>Bank Account Opening:</strong> A bank account in the HUF’s name helps facilitate
                        transactions and manage HUF property and income.</li>
                </ul>
            </section>

        </div>

        <hr>

        <div #Estate class="content-section">

            <h1>Real Estate (Sale Deed, Agreement to Sell, Builder-Buyers Agreements)</h1>
            <section id="real-estate">
                <h2>Need for Compliance</h2>
                <p>Real estate transactions must comply with legal requirements to secure property rights and protect the interests of buyers and sellers. Legal documents like Sale Deeds, Agreements to Sell, and Builder-Buyer Agreements outline transaction details, safeguard ownership, and ensure transparency.</p>
        
                <h2>Relevant Legal Provisions</h2>
                <h3>Transfer of Property Act, 1882</h3>
                <p>Section 54 – Defines and regulates Sale Deeds.</p>
        
                <h3>Registration Act, 1908</h3>
                <p>Section 17 – Mandates registration of Sale Deeds for immovable property transactions.</p>
        
                <h3>RERA Act, 2016 (Real Estate Regulatory Authority Act)</h3>
                <p>Governs Builder-Buyer Agreements, aiming to protect buyers and maintain fair practices in real estate transactions.</p>
        
                <h2>Consequences of Non-Compliance</h2>
                <ul>
                    <li>The Sale Deed may be deemed invalid, thus denying legal ownership to the buyer.</li>
                    <li>Penalties for builders for failing to register with RERA or not delivering properties as per the Builder-Buyer Agreement.</li>
                    <li>Potential legal disputes and inability to sell or mortgage property in the future.</li>
                </ul>
        
                <h2>Compliance Process and Necessary Documents</h2>
                <ul>
                    <li><strong>Sale Deed Drafting and Registration:</strong> The Sale Deed must be signed by both parties and registered within four months of execution under the Registration Act.</li>
                    <li><strong>Stamp Duty Payment:</strong> Required under the state’s Stamp Act regulations.</li>
                    <li><strong>RERA Compliance:</strong> For Builder-Buyer Agreements, the builder must be registered with RERA, and the agreement must include project details, deadlines, and buyer protections.</li>
                </ul>
            </section>

        </div>

        <hr>


        <div #Notices class="content-section">

            <h1>Reply to Legal & Statutory Notices</h1>
            <section id="reply-legal-stat-notices">
                <h2>Need for Compliance</h2>
                <p>Timely and appropriate responses to legal and statutory notices are essential to defend a business’s position, avoid escalation, and maintain good legal standing. Notices may relate to contractual disputes, tax liabilities, or statutory obligations.</p>
        
                <h2>Relevant Legal Provisions</h2>
                <h3>Code of Civil Procedure, 1908</h3>
                <p>Section 80 – Governs notice requirements for legal actions against government bodies.</p>
        
                <h3>Income Tax Act, 1961</h3>
                <p>Section 148 – Allows income tax officers to issue notice for income reassessment.</p>
        
                <h3>Negotiable Instruments Act, 1881</h3>
                <p>Section 138 – Specifies requirements for notice in cases of bounced cheques.</p>
        
                <h2>Consequences of Non-Compliance</h2>
                <ul>
                    <li>Adverse legal consequences, such as default judgments.</li>
                    <li>Imposition of fines or penalties under the respective act (e.g., a penalty under Section 138 for dishonoring cheques).</li>
                    <li>Legal action that could harm the business’s reputation.</li>
                </ul>
        
                <h2>Compliance Process and Necessary Documents</h2>
                <ul>
                    <li><strong>Legal Consultation:</strong> Consulting a lawyer helps prepare an appropriate response, addressing all points raised in the notice.</li>
                    <li><strong>Document Submission:</strong> All relevant documents substantiating your position should be provided along with the reply.</li>
                    <li><strong>Timely Response:</strong> Respond within the stipulated timeframe mentioned in the notice to avoid adverse consequences.</li>
                </ul>
            </section>

        </div>

        <hr>


        <div #HR class="content-section">

            <h1>HR Policies (POSH, Employee Welfare, Labour Laws)</h1>
            <section id="hr-policies">
                <h2>Need for Compliance</h2>
                <p>HR policies for a business are mandated by law to ensure workplace safety, fair treatment, and employee welfare. Policies on prevention of sexual harassment (POSH), employee welfare benefits, and adherence to labour laws are critical to foster a positive and compliant work environment.</p>
        
                <h2>Relevant Legal Provisions</h2>
                <h3>POSH Act, 2013</h3>
                <p>Mandates an Internal Complaints Committee (ICC) to address sexual harassment complaints at the workplace.</p>
        
                <h3>Factories Act, 1948</h3>
                <p>Governs welfare measures, including health, safety, and working conditions.</p>
        
                <h3>Industrial Disputes Act, 1947</h3>
                <p>Provides guidelines for conflict resolution, layoffs, and employee rights in industrial sectors.</p>
        
                <h2>Consequences of Non-Compliance</h2>
                <ul>
                    <li>Penalties under the POSH Act for failing to establish an ICC or conduct mandatory awareness programs.</li>
                    <li>Fines or imprisonment under the Factories Act for health and safety violations.</li>
                    <li>Legal claims and reputational damage.</li>
                </ul>
        
                <h2>Compliance Process and Necessary Documents</h2>
                <ul>
                    <li><strong>POSH Compliance:</strong> Establish an ICC, conduct periodic training, and maintain records of complaints and resolutions.</li>
                    <li><strong>Welfare Benefits and Labour Compliance:</strong> Maintain updated employee records, health and safety measures, and necessary filings with the labour department.</li>
                </ul>
            </section>
            <h1>Labour Laws</h1>
    <section id="labour-laws">
        <h2>Need for Compliance</h2>
        <p>Labour laws ensure that businesses provide fair treatment, wages, and working conditions to employees. Compliance with labour laws not only supports employee welfare but also protects businesses from legal disputes and penalties.</p>

        <h2>Relevant Legal Provisions</h2>
        <h3>Minimum Wages Act, 1948</h3>
        <p>Ensures employees receive a fair minimum wage as per state-specified standards.</p>

        <h3>Employees’ Provident Funds & Miscellaneous Provisions Act, 1952</h3>
        <p>Mandates PF contributions to support employee retirement savings.</p>

        <h3>Employees’ State Insurance Act, 1948</h3>
        <p>Requires health insurance coverage for eligible employees.</p>

        <h2>Consequences of Non-Compliance</h2>
        <ul>
            <li>Fines or penalties under the respective acts (e.g., a penalty for failing to make PF contributions).</li>
            <li>Labour disputes, resulting in reputational damage.</li>
            <li>Potential shutdown of operations by authorities in severe cases of non-compliance.</li>
        </ul>

        <h2>Compliance Process and Necessary Documents</h2>
        <ul>
            <li><strong>Minimum Wage and Overtime Documentation:</strong> Maintain payroll records that comply with minimum wage and overtime laws.</li>
            <li><strong>PF and ESI Compliance:</strong> Ensure timely contributions and filings with relevant authorities.</li>
            <li><strong>Employee Records:</strong> Keep accurate and updated employee records for easy access during audits.</li>
        </ul>
    </section>

        </div>

        <hr>




    </div>
</div>