<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Services</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Services</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>

<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h2>LegalSetup Provide All Kind of Business Solutions</h2>
            <p>
                Our goal is to be the preferred partner for clients navigating legal and regulatory requirements for
                business registration and management in India.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Startup</h3>
                        <p>Registering a business entity is the first step to turning your idea into an actual business.
                            Let’s help you start this journey!.</p>
                        <a routerLink="startup" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>License</h3>
                        <p>Running A Business Without License, Is Like Driving A Car Without Brakes, It’s Bound To
                            Crash. Let us help you to apply the brakes.</p>
                        <a routerLink="license" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Complaince & Tax</h3>
                        <p>Focusing on the high impact tasks could make you 5 times more successful in business. you
                            shouldn’t stress about Compliance. Let us handle this!</p>
                        <a routerLink="complaince-tax" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Documentation</h3>
                        <p>There is no such thing as a gentleman’s agreement, a handshake is not an Agreement. Let us
                            put everything in black & white!</p>
                        <a routerLink="documentation" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Trademark & IPR</h3>
                        <p>In business, trademarks and intellectual property rights are vital for brand protection. Let
                            us handle this for you by registering and preventing unauthorized use.</p>
                        <a routerLink="trademark" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Attestation Services</h3>
                        <p>We are leading player in the fields of Apostille, Embassy Attestation, Legalization, Chamber
                            of Commerce Attestation, HRD Attestation, FRRO Facilitation & related services.</p>
                        <a routerLink="attestation" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>