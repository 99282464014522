<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Solutions Details</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Solutions Details</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div> -->

<!-- <section class="services-details-area ptb-100"> -->
<div class="page-title-area">

    <div class="container">
        <div class="registration-container">
            <div class="text-section">
                <h1><span>
                        Complaince & Tax </span> Complete Compliance & Tax Care</h1>
                <p>
                    LegalSetup’s "Compliance & Tax" services ensure your business stays fully compliant and up-to-date.
                    We handle Changes in Pvt Ltd Companies, LLP Amendments, and Mandatory Annual Filings with precision.
                    Our offerings also cover Labour Compliance, Business Conversions, and GST & Other Indirect Taxes to
                    streamline your regulatory requirements. Trust us to keep your business compliant, so you can focus
                    on growth without the worry of legal complexities.

                </p>
                <div class="stats">
                    <div>
                        <h2>1000+</h2>
                        <p>Happy Customers</p>
                    </div>
                    <div>
                        <h2>20 +</h2>
                        <p>CA & Lawyers</p>
                    </div>
                    <div>
                        <h2>500 +</h2>
                        <p>Filings Every Month</p>
                    </div>
                </div>

                <div class="buttons">
                    <a href="tel:+91 9911566199" target="_blank"><button class="btn-schedule">Schedule Free
                            Consultation</button></a>
                    <button routerLink="/about" class="btn-info">See How It Works</button>
                </div>
            </div>
            <div class="image-section">
                <img src="../../../assets/images/gstCertificate.jpg" alt="Certificate">
                <img src="../../../../assets/images/google-review.png" alt="Google Customer Reviews"
                    class="review-badge">
            </div>
        </div>
    </div>
    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<!-- </section> -->
<div class="container">
    <!-- Fixed Sidebar with Buttons to Navigate to Sections -->
    <div class="sidebar">
        <button class="sidebar-btn" (click)="scrollToSection('Pvt')" [class.active]="activeSection === 'Pvt'">Changes in
            Pvt Ltd Company
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Limited')" [class.active]="activeSection === 'Limited'">
            Changes in Limited Liability Partnership
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Filings')"
            [class.active]="activeSection === 'Filings'">Mandatory Annual Filings
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Labour')"
            [class.active]="activeSection === 'Labour'">Labour
            Compliance
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Convert')"
            [class.active]="activeSection === 'Convert'">Convert
            Your Business
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Tax')" [class.active]="activeSection === 'Tax'">GST &
            Other Indirect Tax
        </button>



    </div>

    <!-- Main Content Area with Anchored Sections -->
    <div class="content-wrapper">




        <div #Pvt class="content-section">
            <!-- Changes in Private Limited Company -->
            <section id="changes-private-limited-company">
                <h1>Changes in Private Limited Company</h1>
                <h2>Introduction to Private Limited Companies</h2>
                <p>Private Limited Companies have distinct benefits and require compliance to operate smoothly. Here’s a
                    breakdown of the key areas where changes may be necessary.</p>

                <h2>Common Changes in a Private Limited Company</h2>
                <h3>1. Change in Registered Office</h3>
                <p>Changes to the registered office can occur within the same city, within the same state, or
                    interstate. Proper filings like Form INC-22 are required.</p>

                <h3>2. Changes in Directors</h3>
                <p>Adding or removing directors requires forms like DIR-12, along with board and shareholder
                    resolutions.</p>

                <h3>3. Changes in Company Name</h3>
                <p>Changing the company name involves updating the MOA and AOA and filing Form INC-24.</p>

                <h3>4. Changes in Share Capital</h3>
                <p>Capital adjustments such as issuing new shares or transferring them require filings like Form SH-7
                    and PAS-3.</p>

                <h3>5. Amendments in Memorandum and Articles of Association (MOA & AOA)</h3>
                <p>Alterations to MOA and AOA can be made based on business needs, requiring Form MGT-14.</p>

                <h2>Legal and Financial Implications</h2>
                <p>Company changes have tax and regulatory compliance impacts. Non-compliance may lead to penalties.</p>

                <h2>Timeline and Fees</h2>
                <p>Each change has specific timelines and associated fees. It’s essential to budget for these expenses.
                </p>

                <h2>FAQs and Common Issues</h2>
                <p>Here are some frequently asked questions and solutions for common issues encountered during
                    transitions.</p>
            </section>

        </div>

        <hr>

        <div #Limited class="content-section">

           <!-- Changes in Limited Liability Partnership -->
    <section id="changes-llp">
        <h1>Changes in Limited Liability Partnership (LLP)</h1>
        <h2>Introduction to LLPs</h2>
        <p>Limited Liability Partnerships (LLPs) offer flexibility and require certain compliance measures.</p>

        <h2>Common Changes in an LLP</h2>
        <h3>1. Change in Registered Office</h3>
        <p>LLPs changing office locations need to submit the appropriate filings, such as Form LLP-3.</p>

        <h3>2. Changes in Partners</h3>
        <p>Adding or removing partners necessitates filing Form LLP-4 and appropriate documentation.</p>

        <h3>3. Change in LLP Agreement</h3>
        <p>Any amendments to the LLP Agreement require Form LLP-3.</p>

        <h3>4. Changes in Capital Contribution</h3>
        <p>Increases or decreases in capital contribution should align with regulatory guidelines.</p>

        <h3>5. Change in Name</h3>
        <p>To change an LLP’s name, necessary approvals and Form LLP-5 are required.</p>

        <h2>Legal and Compliance Implications</h2>
        <p>LLP changes affect legal standing and tax obligations, with potential penalties for non-compliance.</p>

        <h2>Process Timeline and Fees</h2>
        <p>Each change has estimated timelines and government fees, essential for planning.</p>

        <h2>FAQs and Practical Insights</h2>
        <p>Find answers to common questions and practical tips for LLP compliance.</p>
    </section>
        </div>

        <hr>

        <div #Filings class="content-section">

            <!-- Mandatory Annual Filings -->
    <section id="mandatory-annual-filings">
        <h1>Mandatory Annual Filings</h1>
        <h2>Importance of Annual Filings for Businesses</h2>
        <p>Annual filings are essential for maintaining compliance in companies and LLPs. Here are key filing requirements.</p>

        <h2>Annual Filings for Private Limited Companies</h2>
        <h3>1. Annual Return (MGT-7)</h3>
        <p>Filing an annual return is mandatory. This return requires specific documents.</p>

        <h3>2. Financial Statements (AOC-4)</h3>
        <p>Submitting financial statements by the deadline ensures regulatory compliance.</p>

        <h3>3. Director KYC</h3>
        <p>Director KYC (e.g., DIR-3 KYC) is required for transparency, with penalties for non-compliance.</p>

        <h3>4. Other Annual Compliances</h3>
        <p>Companies must also conduct AGMs and maintain records of board meetings.</p>

        <h2>Annual Filings for LLPs</h2>
        <h3>1. Form LLP-8 (Statement of Account and Solvency)</h3>
        <p>LLPs are required to submit this form as a solvency statement.</p>

        <h3>2. Form LLP-11 (Annual Return)</h3>
        <p>This form is mandatory for LLPs to report annual activities.</p>

        <h2>Penalties for Non-Compliance</h2>
        <p>Missed deadlines lead to penalties, which affect a business’s reputation and compliance status.</p>

        <h2>Common Filing Issues and Solutions</h2>
        <p>Solutions for common filing issues help businesses stay compliant.</p>
    </section>

        </div>

        <hr>


        <div #Labour class="content-section">

             <!-- Labour Compliance -->
    <section id="labour-compliance">
        <h1>Labour Compliance</h1>
        <h2>Importance of Labour Compliance</h2>
        <p>Labour compliance is vital for business operations, employee welfare, and legal requirements.</p>

        <h2>Types of Labour Compliance</h2>
        <h3>1. Registration and Licensing</h3>
        <p>Compliance includes Shops and Establishment Act registration, licenses, and more.</p>

        <h3>2. Payroll Compliance</h3>
        <p>Businesses must adhere to wage laws and payroll regulations.</p>

        <h3>3. Employee Welfare Compliance</h3>
        <p>Includes schemes like provident fund and Employee State Insurance (ESI).</p>

        <h3>4. Health and Safety Compliance</h3>
        <p>Regulations cover workplace health, safety, and industry-specific needs.</p>

        <h2>Compliance Process and Filing Requirements</h2>
        <p>Overview of filing procedures and documentation requirements.</p>

        <h2>Penalties for Non-Compliance</h2>
        <p>Non-compliance can lead to penalties, affecting reputation and employee morale.</p>

        <h2>Best Practices for Labour Compliance</h2>
        <p>Practical tips to ensure awareness and safety among employees.</p>
    </section>

        </div>

        <hr>


        <div #Convert class="content-section">

            <!-- Convert Your Business -->
    <section id="convert-your-business">
        <h1>Convert Your Business</h1>
        <h2>Why Businesses Choose to Convert</h2>
        <p>Businesses often convert structures for growth, tax advantages, and liability benefits. Choosing the right structure is essential.</p>

        <h2>Types of Business Conversions</h2>
        <h3>1. Sole Proprietorship to Private Limited Company</h3>
        <p>Process, legal requirements, and documentation for conversion.</p>

        <h3>2. LLP to Private Limited Company</h3>
        <p>Steps, regulatory requirements, and implications of converting an LLP.</p>

        <h3>3. Partnership Firm to LLP</h3>
        <p>Benefits and process of converting a partnership into an LLP.</p>

        <h3>4. Private Limited Company to Public Limited Company</h3>
        <p>Steps and compliance requirements for conversion.</p>

        <h2>Legal and Compliance Considerations</h2>
        <p>Key legal and tax aspects for each conversion type.</p>

        <h2>Timeline and Costs of Conversion</h2>
        <p>Expected timelines and associated government fees for conversions.</p>

        <h2>Common Issues and FAQs</h2>
        <p>Common queries and troubleshooting tips for business conversions.</p>
    </section>
        </div>

        <hr>



        <div #Tax class="content-section">

           <!-- GST and Other Indirect Tax Compliance -->
    <section id="gst-indirect-tax-compliance">
        <h1>GST and Other Indirect Tax Compliance</h1>
        <h2>Introduction to GST Compliance</h2>
        <p>Goods and Services Tax (GST) compliance is essential for smooth business operations. It covers filing returns, maintaining records, and timely payment of taxes.</p>

        <h2>Key Areas of GST Compliance</h2>
        <h3>1. GST Registration</h3>
        <p>Registration is mandatory for businesses with specific turnover. Proper registration ensures tax credit eligibility.</p>

        <h3>2. Filing GST Returns</h3>
        <p>Monthly, quarterly, and annual returns are required based on the nature of transactions.</p>

        <h3>3. GST Invoicing</h3>
        <p>GST-compliant invoices are crucial to avoid penalties.</p>

        <h3>4. Record Maintenance</h3>
        <p>Accurate records of sales, purchases, and stock help in audits and compliance.</p>

        <h2>Other Indirect Taxes</h2>
        <p>Businesses may also need to comply with other indirect taxes like customs duties and state-specific taxes.</p>

        <h2>Penalties for Non-Compliance</h2>
        <p>Failure to comply with GST regulations leads to penalties and potential legal issues.</p>

        <h2>Tips for Smooth GST Compliance</h2>
        <p>Best practices for maintaining compliance, including regular audits and timely filing.</p>
    </section>
        </div>

        <hr>




    </div>
</div>