<section class="banner-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-lg-6">
                        <div class="banner-content">
                            <h1>Formation, Compliance and Contracts </h1>
                            <h2 class="heading2">Your All-in-One Business Solution</h2>
                            <!-- <h3>Our Services</h3> -->
                            <div class="buttons">
                                <button class="btn">TRADEMARK REGISTRATION</button>
                                <button class="btn">BUSINESS CONTRACTS</button>
                                <button class="btn">FOOD LICENSE</button>
                                <button class="btn">GST REGISTRATION</button>
                                <button class="btn">COMPANY FORMATION</button>
                            </div>
                            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, et sed do eiusmod tempor
                                incididunt labore dolore magna aliqua. Quis ipsum suspendisse.</p> -->
                            <div class="banner-btn">
                                <a routerLink="/contact" class="box-btn">Contact Us</a>
                                <a routerLink="/about" class="box-btn border-btn">Know More</a>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="banner-img">
                            <img src="assets/images/home-bg-1-img.png" alt="banner-img" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="home-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape"></div>
        <div class="shape2"><img src="assets/images/shape/2.png" alt="shape"></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape"></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape"></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape"></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape"></div>
    </div>
</section>

<section class="home-service-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Our Services</span>
            <h2>LegalSetup Provide All Kind of Business Solutions</h2>
            <p>
                Our goal is to be the preferred partner for clients navigating legal and regulatory requirements for
                business registration and management in India.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s1.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Startup</h3>
                        <p>Registering a business entity is the first step to turning your idea into an actual business.
                            Let’s help you start this journey!.</p>
                        <a routerLink="startup" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s2.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>License</h3>
                        <p>Running A Business Without License, Is Like Driving A Car Without Brakes, It’s Bound To
                            Crash. Let us help you to apply the brakes.</p>
                        <a routerLink="license" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s3.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Complaince & Tax</h3>
                        <p>Focusing on the high impact tasks could make you 5 times more successful in business. you
                            shouldn’t stress about Compliance. Let us handle this!</p>
                        <a routerLink="complaince-tax" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s4.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Documentation</h3>
                        <p>There is no such thing as a gentleman’s agreement, a handshake is not an Agreement. Let us
                            put everything in black & white!</p>
                        <a routerLink="documentation" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s5.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Trademark & IPR</h3>
                        <p>In business, trademarks and intellectual property rights are vital for brand protection. Let
                            us handle this for you by registering and preventing unauthorized use.</p>
                        <a routerLink="trademark" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-service">
                    <div class="service-img">
                        <img src="assets/images/service/s6.png" alt="service" />
                    </div>

                    <div class="service-content">
                        <h3>Attestation Services</h3>
                        <p>We are leading player in the fields of Apostille, Embassy Attestation, Legalization, Chamber
                            of Commerce Attestation, HRD Attestation, FRRO Facilitation & related services.</p>
                        <a routerLink="attestation" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-company-area bg-color">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="company-content">
                    <div class="company-tittle">
                        <span>Our Company</span>
                        <h2>Your Virtual Legal Department</h2>
                        <p>We are in the business of ensuring legal and financial inclusion by simplifying legal
                            services and solutions to the problems Entrepreneurs face everyday on their journey. The
                            need for legal documentation in everyday life and business cannot be overemphasized. Since
                            1992, we have been bridging the legal gap and fostering seamless business transactions
                            across India.</p>
                        <p>Together with our team of energetic, forward thinking, young Professionals, LegalSetup is
                            building a roadmap to the future of how legal services should be offered and we are fixated
                            on delivering on this promise. We aim to deliver value by staying abreast with current and
                            all developing technologies and in order to be a distinguished industry contributor.</p>
                    </div>

                    <a routerLink="/about" class="box-btn">Know More</a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="company-img">
                    <img src="../../../../assets/images/home1.JPG" alt="company" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="choose-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Choose LegalStartup</span>
            <h2>We Achieved People’s Trust by Our Great Service</h2>
            <p>"Launch Your Dream: Expert Support for Startup Registration!"
            </p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="choose-img">
                    <img src="assets/images/choose-img.png" alt="choose" />
                </div>
            </div>

            <div class="col-lg-6">
                <div class="choose-content">
                    <div class="faq-accordion">
                        <accordion>
                            <accordion-group [isOpened]="true" heading="1. What is a Legal Setup?
                            ">
                                <p>Legal Setup provides remote legal support services to businesses
                                    and organizations. This setup allows entities to access legal expertise without the
                                    overhead costs of maintaining an in-house team.
                                </p>
                            </accordion-group>
                            <accordion-group heading=" What are the key benefits ?
                            ">
                                <p> <b> Cost Efficiency</b>: Reduces overhead costs associated with hiring full-time
                                    staff,
                                    office space, and benefits.</p>
                                <p> <b>Access to Expertise</b>: Gain access to experienced legal professionals with
                                    various
                                    specialties as needed.</p>
                                <p> <b>Scalability</b>: Easily scale services up or down based on your business needs
                                    and case
                                    load.</p>
                                <p> <b> Flexibility</b>: Services can be customized to fit your unique requirements and
                                    can be
                                    accessed as needed.
                                </p>
                            </accordion-group>
                            <accordion-group heading="How do I get started ?

                            ">
                                <p>Contact us to discuss your specific legal needs. We will assess your requirements and
                                    customize a service package that fits your business, ensuring you receive the
                                    support you need.</p>
                            </accordion-group>
                            <accordion-group heading="What are the costs associated with a Legal Setup?
                            ">
                                <p>Costs are typically based on the services provided and can be structured as hourly
                                    rates or flat fees, offering flexibility to fit your budget. This is often more
                                    economical than hiring full-time staff.
                                </p>
                            </accordion-group>
                        </accordion>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-process-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Working Process</span>
            <h2>We are popular because of our way of working</h2>
            <p>Trust, Transparency and Tailored Solution.</p>
        </div>

        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p2.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Free Consultation</h3>
                        <p>Our expert consultants will connect with you to understand your business need and we will
                            provide you legal and valid solution for your business
                        </p>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p1.png" alt="process" />
                        <span class="pro-span"><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>
                    <div class="content">
                        <h3>Documentation</h3>
                        <p>End to end online documentation, No need to visit any office!! You just sit back and relax.
                            Our documentation team will complete the process
                        </p>
                    </div>

                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p3.png" alt="process" />
                        <span><img src="assets/images/process/next.png" alt="shape" /></span>
                    </div>

                    <div class="content">
                        <h3>Document Review</h3>
                        <p>
                            Our team will review all required documents to ensure compliance and accuracy, making sure
                            your legal needs are fully met.
                        </p>
                    </div>


                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-process">
                    <div class="icon">
                        <img src="assets/images/process/p4.png" alt="process" />
                    </div>

                    <div class="content">
                        <h3>
                            Delivery</h3>
                        <p>Get the document delivered at your Home!!!
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-case ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Services</span>
            <h2>Our Featured Services</h2>
            <p>Our Featured Services showcase a tailored selection of expert legal solutions designed to meet your
                unique business needs, ensuring efficiency and compliance at every step</p>
        </div>
        <div class="case">
            <ngx-tabset>
                <ngx-tab tabTitle="Startup">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="startup"><img
                                            src="../../../../assets/images/services/2.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="startup">
                                        <h3>Incorporation</h3>
                                    </a>
                                    <p>Our service for incorporating a body corporate in India simplifies the process,
                                        guiding you through legal requirements and paperwork for various entity types.
                                        With expert support, we ensure compliance and allow you to focus on
                                        your business goals.</p>
                                    <a routerLink="startup" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="startup"><img
                                            src="../../../../assets/images/services/3.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="startup">
                                        <h3>Company Master Documents
                                        </h3>
                                    </a>
                                    <p>Our paralegal services for Company Master Data in India ensure accurate
                                        maintenance and compliance of your business information with regulatory
                                        requirements. We streamline data management, helping you stay organized and
                                        up-to-date with filings and disclosures.</p>
                                    <a routerLink="startup" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="startup"><img
                                            src="../../../../assets/images/services/4.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="startup">
                                        <h3>DSC Services</h3>
                                    </a>
                                    <p>Our Digital Signature Certificate services provide secure & legally recognized
                                        solutions for signing documents electronically. We simplify the application
                                        process, ensuring quick issuance and compliance with regulatory standards. Trust
                                        us to enhance your business efficiency & facilitate seamless
                                        digital transactions</p>
                                    <a routerLink="startup" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="startup"><img
                                            src="../../../../assets/images/services/din2.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="startup">
                                        <h3>DIN Services</h3>
                                    </a>
                                    <p>Our Director Identification Services facilitate the seamless application for
                                        Director Identification Numbers (DIN) as mandated by the Ministry of Corporate
                                        Affairs (MCA). We ensure compliance with regulatory requirements and assist in
                                        the timely processing of applications. With our expert guidance, directors can
                                        focus on their roles while we handle the administrative details.</p>
                                    <a routerLink="startup" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="startup"><img
                                            src="../../../../assets/images/services/6.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="startup">
                                        <h3>Company/LLP Name Availability
                                        </h3>
                                    </a>
                                    <p>

                                        Quickly check the availability of your preferred company or LLP name with our
                                        instant search tool.
                                        Ensure your name is unique and meets all regulatory guidelines.
                                        Simplify the first step in your business journey with a compliant name.
                                        Get verified results instantly to secure your brand identity.</p>
                                    <a routerLink="startup" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c6.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>GST Registration
                                        </h3>
                                    </a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="License">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="license"><img
                                            src="../../../../assets/images/gstCertificate.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="license">
                                        <h3>GST Registration
                                        </h3>
                                    </a>
                                    <p>Register for GST seamlessly with our streamlined service.
                                        Our team ensures accurate documentation, quick processing, and hassle-free
                                        registration.
                                        Stay compliant with tax regulations and unlock benefits for your business.
                                        Begin your GST registration today and pave the way for growth and transparency.
                                    </p>
                                    <a routerLink="license" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="license"><img
                                            src="../../../../assets/images/services/MSME.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="license">
                                        <h3>MSME Registration
                                        </h3>
                                    </a>
                                    <p>Empower your business with our MSME Registration service, designed for micro,
                                        small, and medium enterprises.
                                        Enjoy government benefits like subsidies, tax exemptions, and easier loan
                                        approvals.
                                        Our team simplifies the registration process for quick, accurate results.
                                        Get MSME certified today and unlock exclusive growth opportunities for your
                                        business.</p>
                                    <a routerLink="license" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="license"><img
                                            src="../../../../assets/images/services/Tax.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="license">
                                        <h3>80 IAC Tax Exemption
                                        </h3>
                                    </a>
                                    <p>Maximize your NGO or trust's impact with our 80 IAC Tax Exemption service,
                                        allowing donors to receive tax deductions for their contributions. Our team
                                        guides you through the certification process for a seamless, compliant
                                        experience. Empower your organization to attract more donations while ensuring
                                        full regulatory compliance.</p>
                                    <a routerLink="license" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="license"><img
                                            src="../../../../assets/images/services/import.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="license">
                                        <h3>Importer Exporter Code Registration

                                        </h3>
                                    </a>
                                    <p>Expand your business internationally with our Importer Exporter Code (IEC)
                                        Registration service. The IEC is essential for businesses engaging in import and
                                        export activities, enabling smooth customs clearance and financial transactions.
                                        Our team simplifies the application process, ensuring fast, accurate
                                        registration so you can focus on growing your global reach.</p>
                                    <a routerLink="license" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="license"><img
                                            src="../../../../assets/images/services/licence.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>FSSAI Registration
                                        </h3>
                                    </a>
                                    <p>Ensure your food business complies with national safety standards through our
                                        FSSAI Registration service. We streamline the process, handling documentation
                                        and filing for a quick, hassle-free experience. With FSSAI certification, you
                                        boost consumer trust, meet legal requirements, and enhance your brand's
                                        credibility in the food industry.</p>
                                    <a routerLink="license" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="license"><img
                                            src="../../../../assets/images/services/shop.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="license">
                                        <h3>Shop And Establishments Registration
                                        </h3>
                                    </a>
                                    <p>Comply with local regulations effortlessly through our Shop and Establishment
                                        Registration service. We assist you in navigating the application process,
                                        ensuring all necessary documentation is in order for a swift approval. Secure
                                        your business's legitimacy and protect your rights as an employer while
                                        fostering a transparent working environment</p>
                                    <a routerLink="license" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="license"><img
                                            src="../../../../assets/images/services/esi.JPG" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="license">
                                        <h3>ESI Registration
                                        </h3>
                                    </a>
                                    <p>Ensure the welfare of your employees with our ESI Registration service, which
                                        provides access to health and social security benefits. Our team simplifies the
                                        registration process, handling all necessary documentation for a smooth
                                        experience. Comply with legal requirements while safeguarding your workforce's
                                        well-being, fostering a supportive work environment.</p>
                                    <a routerLink="license" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="license"><img
                                            src="../../../../assets/images/services/Tan.JPG" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="license">
                                        <h3>TAN Registration
                                        </h3>
                                    </a>
                                    <p>Streamline your tax compliance with our TAN Registration service, essential for
                                        deducting or collecting tax at source. Our expert team guides you through the
                                        application process, ensuring all documentation is accurate and submitted
                                        promptly. Secure your Tax Deduction and Collection Account Number (TAN) with
                                        ease, and stay compliant with tax regulations.</p>
                                    <a routerLink="license" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="license"><img
                                            src="../../../../assets/images/services/pan.JPG" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="license">
                                        <h3>PAN Registration
                                        </h3>
                                    </a>
                                    <p>Facilitate your financial transactions and comply with tax regulations through
                                        our PAN Registration service. Our experienced team simplifies the application
                                        process, ensuring accurate documentation for quick approval. Obtain your
                                        Permanent Account Number (PAN) effortlessly, enabling seamless banking,
                                        investment, and tax filing for individuals and businesses alike.</p>
                                    <a routerLink="license" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="license"><img
                                            src="../../../../assets/images/services/pf.JPG" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="license">
                                        <h3>PF Registration
                                        </h3>
                                    </a>
                                    <p>Secure your employees' future with our PF Registration service, ensuring
                                        compliance with the Employees' Provident Fund (EPF) scheme. We guide you through
                                        the registration process, handling all necessary documentation for a hassle-free
                                        experience. Provide your workforce with essential retirement benefits while
                                        fostering a positive and compliant workplace environment.</p>
                                    <a routerLink="license" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="license"><img
                                            src="../../../../assets/images/services/drug10.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="license">
                                        <h3>Drug License
                                        </h3>
                                    </a>
                                    <p>Ensure the legal operation of your pharmaceutical business with our Drug License
                                        registration service. We assist you in navigating the complex application
                                        process, ensuring all necessary documentation is prepared accurately and
                                        submitted on time. Obtain your Drug License efficiently, allowing you to
                                        distribute and sell medications while complying with regulatory standards.</p>
                                    <a routerLink="license" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="../../../../assets/images/services/esi.JPG"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>GST Registration Cancellation
                                        </h3>
                                    </a>
                                    <p>Streamline the process of closing your GST registration with our GST Registration
                                        Cancellation service. Our experienced team guides you through the necessary
                                        steps and documentation required for a hassle-free cancellation. Ensure
                                        compliance with tax regulations while effectively managing your business
                                        obligations and preventing future liabilities.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div> -->

                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Complaince & Tax">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="complaince-tax"><img
                                            src="../../../../assets/images/services/pvt.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="complaince-tax">
                                        <h3>Changes in Pvt Ltd Company</h3>
                                    </a>
                                    <p>Manage changes in your Private Limited Company effortlessly with our dedicated
                                        services. Whether you're altering your company name, address, share capital, or
                                        directors, our team provides expert guidance through the regulatory process.
                                        Ensure compliance with all legal requirements while making necessary adjustments
                                        to support your business growth and development.</p>
                                    <a routerLink="complaince-tax" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="complaince-tax"><img
                                            src="../../../../assets/images/services/llp.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="complaince-tax">
                                        <h3>Changes in Limited Liability Partnership</h3>
                                    </a>
                                    <p>Effortlessly manage changes in your Limited Liability Partnership (LLP) with our
                                        specialized services. Whether you need to update your LLP agreement, add or
                                        remove partners, or change your registered office address, our team guides you
                                        through the necessary compliance procedures. Ensure that all modifications meet
                                        regulatory requirements while supporting the growth and adaptability of your
                                        business.</p>
                                    <a routerLink="complaince-tax" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="complaince-tax"><img
                                            src="../../../../assets/images/services/filing 2.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="complaince-tax">
                                        <h3>Mandatory Annual Filings</h3>
                                    </a>
                                    <p>Stay compliant with regulatory requirements through our Mandatory Annual Filings
                                        service. We handle all essential filings, including annual returns, financial
                                        statements, and compliance reports, ensuring your business meets legal
                                        obligations on time. Our expert team simplifies the process, allowing you to
                                        focus on growth while maintaining transparency and accountability.</p>
                                    <a routerLink="complaince-tax"class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="complaince-tax"><img
                                            src="../../../../assets/images/services/labour.JPG" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="complaince-tax">
                                        <h3>Labour Compliance
                                        </h3>
                                    </a>
                                    <p>Ensure your business meets all legal requirements with our comprehensive Labour
                                        Compliance service. We help you navigate the complex landscape of labor laws,
                                        including registration, documentation, and reporting obligations. Our expert
                                        team provides tailored solutions to maintain compliance, protect your workforce,
                                        and foster a positive work environment.</p>
                                    <a routerLink="complaince-tax" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="complaince-tax"><img
                                            src="../../../../assets/images/services/converturbusiness.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="complaince-tax">
                                        <h3>Convert Your Business
                                        </h3>
                                    </a>
                                    <p>Transform your business structure seamlessly with our Convert Your Business
                                        service. Whether you're transitioning from a sole proprietorship to a
                                        partnership or from a partnership to a Private Limited Company, our expert team
                                        guides you through every step of the process. Ensure compliance with legal
                                        requirements while optimizing your business for growth and success.</p>
                                    <a routerLink="complaince-tax" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="complaince-tax"><img
                                            src="../../../../assets/images/services/gstindirect.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="complaince-tax">
                                        <h3>GST & Other Indirect Tax</h3>
                                    </a>
                                    <p>Navigate the complexities of GST and other indirect taxes with our expert
                                        services. We provide comprehensive support in GST registration, filing returns,
                                        and ensuring compliance with all applicable regulations. Our team also offers
                                        insights into other indirect taxes, helping you optimize your tax strategy and
                                        minimize liabilities while maintaining full compliance.</p>
                                    <a routerLink="complaince-tax" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Documentation">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="documentation"><img
                                            src="../../../../assets/images/services/busniesscontract.JPG"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="documentation">
                                        <h3>Business Contracts
                                        </h3>
                                    </a>
                                    <p>Protect your interests and foster clear communication with our Business Contracts
                                        service. We specialize in drafting, reviewing, and negotiating a wide range of
                                        contracts tailored to your specific needs, including partnership agreements,
                                        service contracts, and non-disclosure agreements. Our experienced team ensures
                                        that all contracts comply with legal standards, providing you with peace of mind
                                        as you conduct your business</p>
                                    <a routerLink="documentation" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="documentation"><img
                                            src="../../../../assets/images/services/Huf.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="documentation">
                                        <h3>Setup of HUF
                                        </h3>
                                    </a>
                                    <p>A Hindu Undivided Family (HUF) is a unique legal entity recognized under Indian
                                        law, primarily for tax benefits and asset management. It consists of individuals
                                        related by blood and is governed by Hindu personal law. Establishing an HUF can
                                        enhance financial planning and provide a streamlined approach to wealth
                                        distribution among family members. Our legal services assist in the formation
                                        and registration of HUFs, ensuring compliance with all legal requirements.</p>
                                    <a routerLink="documentation" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="documentation"><img
                                            src="../../../../assets/images/services/realestate.JPG" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="documentation">
                                        <h3>Real Estate
                                        </h3>
                                    </a>
                                    <p>Navigate the complexities of real estate transactions with our expert services.
                                        We provide comprehensive support for property buying, selling, leasing, and
                                        management, ensuring compliance with all legal requirements. Our experienced
                                        team assists you in drafting contracts, conducting due diligence, and
                                        negotiating favorable terms, making your real estate experience smooth and
                                        successful.</p>
                                    <a routerLink="documentation" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="documentation"><img
                                            src="../../../../assets/images/services/notice.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="documentation">
                                        <h3>Notices</h3>
                                    </a>
                                    <p>Ensure clarity and compliance in your communications with our Notices service. We
                                        assist in drafting, reviewing, and sending various types of notices, including
                                        legal, business, and formal notifications. Our expert team ensures that all
                                        notices are clear, legally sound, and delivered promptly, helping you protect
                                        your interests and maintain transparency in your dealings.</p>
                                    <a routerLink="documentation" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="documentation"><img
                                            src="../../../../assets/images/services/hr.JPG" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="documentation">
                                        <h3>HR Policies
                                        </h3>
                                    </a>
                                    <p>Establish a strong organizational framework with our HR Policies service. We help
                                        you develop comprehensive human resource policies tailored to your business
                                        needs, covering areas such as recruitment, employee conduct, performance
                                        management, and workplace safety. Our expert team ensures that your policies
                                        comply with legal standards, fostering a positive work environment and promoting
                                        consistency in your HR practices.</p>
                                    <a routerLink="documentation" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>Parking Management & Web Developing</h3>
                                    </a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </ngx-tab>

                <!-- 5 -->
                <ngx-tab tabTitle="Trademark & IPR">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="trademark"><img
                                            src="../../../../assets/images/services/trademark.JPG" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="trademark">
                                        <h3>Trademark</h3>
                                    </a>
                                    <p>Safeguard your brand identity with our Trademark registration service. We guide
                                        you through the process of securing your trademark, ensuring that your logos,
                                        names, and symbols are protected from unauthorized use. Our expert team conducts
                                        thorough searches and handles all necessary documentation, helping you establish
                                        and maintain your brand's uniqueness in the market.</p>
                                    <a routerLink="trademark" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="trademark"><img
                                            src="../../../../assets/images/services/copyright.JPG" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="trademark">
                                        <h3>Copyright</h3>
                                    </a>
                                    <p>Protect your creative works with our Copyright registration service. We assist
                                        you in securing your intellectual property rights for various creations,
                                        including literature, music, art, and software. Our expert team ensures that
                                        your works are properly registered, helping you prevent unauthorized use and
                                        infringement while preserving your rights as a creator.</p>
                                    <a routerLink="trademark" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="trademark"><img
                                            src="../../../../assets/images/services/patent1.JPG" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="trademark">
                                        <h3>Patent</h3>
                                    </a>
                                    <p>Secure your innovations with our Patent registration service. We guide you
                                        through the complex process of patenting your inventions, ensuring that your
                                        intellectual property is protected from unauthorized use. Our expert team
                                        conducts thorough searches, prepares necessary documentation, and provides
                                        ongoing support to help you navigate patent laws and maintain your competitive
                                        edge in the market.</p>
                                    <a routerLink="trademark" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="trademark"><img
                                            src="../../../../assets/images/services/infrigment.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="trademark">
                                        <h3>Infringement</h3>
                                    </a>
                                    <p>Protect your intellectual property with our Infringement services, designed to
                                        address unauthorized use of your creations. Our expert team conducts thorough
                                        investigations to identify potential infringements and provides tailored
                                        strategies for enforcement. Whether through negotiation, cease and desist
                                        letters, or litigation support, we help you safeguard your rights and uphold the
                                        integrity of your brand</p>
                                    <a routerLink="trademark" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c4.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>IT Software Company Development Case</h3>
                                    </a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="/case-studies-details"><img src="assets/images/case/c5.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="/case-studies-details">
                                        <h3>Parking Management & Web Developing</h3>
                                    </a>
                                    <p>Lorem ipsum dolor sit amet, coloni is to nsectetur adipiscing elit, sed do eiudvi
                                        smod tempor incididunt ipsum dolor.</p>
                                    <a routerLink="/case-studies-details" class="line-bnt">View Project Details</a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </ngx-tab>

                <!-- 6 -->
                <ngx-tab tabTitle="Attestation Services">
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="attestation"><img
                                            src="../../../../assets/images/services/Appostile.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="attestation">
                                        <h3>Apostille</h3>
                                    </a>
                                    <p>Facilitate international document acceptance with our Apostille service. We
                                        assist in obtaining Apostille certifications for various legal documents,
                                        ensuring they are recognized in countries that are part of the Hague Convention.
                                        Our expert team streamlines the process, handling all necessary paperwork and
                                        requirements, so you can confidently navigate global transactions and legal
                                        matters.</p>
                                    <a routerLink="attestation" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="attestation"><img
                                            src="../../../../assets/images/services/embasyattestation.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="attestation">
                                        <h3>Embassy Attestation
                                        </h3>
                                    </a>
                                    <p>Ensure the authenticity of your documents with our Embassy Attestation service.
                                        We help you navigate the complex process of getting your legal documents
                                        verified by the relevant embassy or consulate, ensuring they meet international
                                        standards. Our experienced team manages all the required paperwork and
                                        procedures, allowing you to confidently use your documents abroad for various
                                        purposes.</p>
                                    <a routerLink="attestation" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="attestation"><img
                                            src="../../../../assets/images/services/legalization.JPG" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="attestation">
                                        <h3>Legalization</h3>
                                    </a>
                                    <p>Streamline the process of document legalization with our comprehensive service.
                                        We assist in obtaining the necessary approvals and certifications for your
                                        documents to be recognized internationally, ensuring compliance with local laws
                                        and regulations. Our expert team handles all the complexities involved in
                                        legalization, providing you with peace of mind as you prepare your documents for
                                        use abroad.</p>
                                    <a routerLink="attestation" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="attestation"><img
                                            src="../../../../assets/images/services/chamberofattestation.jpg"
                                            alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="attestation">
                                        <h3>Chamber of Commerce Attestation
                                        </h3>
                                    </a>
                                    <p>

                                        Chamber of Commerce attestation is a verification process carried out by the
                                        local Chamber of Commerce to authenticate business documents. This attestation
                                        is often required for various purposes, such as export-import activities,
                                        business contracts, or regulatory compliance. The process typically involves
                                        submitting the documents for verification, after which the Chamber issues a
                                        certificate of attestation, confirming the authenticity of the documents. Our
                                        legal services can assist you in navigating the attestation process efficiently,
                                        ensuring that all required documents are properly authenticated.</p>
                                    <a routerLink="attestation" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="attestation"><img
                                            src="../../../../assets/images/services/translator.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="attestation">
                                        <h3>Translation</h3>
                                    </a>
                                    <p>Translation of legal documentation involves converting legal texts from one
                                        language to another while maintaining accuracy and preserving the intended legal
                                        meaning. This process is crucial for contracts, agreements, court documents, and
                                        other legal materials, as even minor errors can lead to misunderstandings or
                                        legal disputes. Professional legal translators with expertise in both the source
                                        and target languages are essential to ensure compliance with legal standards and
                                        terminology. Our services provide precise and reliable translations of legal
                                        documents to meet your needs.
                                    </p>
                                    <a routerLink="attestation" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="attestation"><img
                                            src="../../../../assets/images/services/Hrd.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="attestation">
                                        <h3>HRD Attestation
                                        </h3>
                                    </a>
                                    <p>HRD attestation refers to the verification and authentication of educational
                                        documents by the Human Resource Development (HRD) Ministry in India. This
                                        process is often required for individuals seeking employment or pursuing higher
                                        studies abroad. The HRD attestation confirms the authenticity of the educational
                                        qualifications, ensuring that the documents meet the necessary legal standards.
                                        Our services streamline the HRD attestation process, guiding you through the
                                        required steps to ensure your documents are duly verified and accepted for
                                        international use.</p>
                                    <a routerLink="attestation" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row case-list">
                        <div class="col-lg-4 col-sm-6">
                            <div class="single-case">
                                <div class="case-img">
                                    <a routerLink="attestation"><img
                                            src="../../../../assets/images/services/gstindirect.jpg" alt="case" /></a>
                                </div>

                                <div class="content">
                                    <a routerLink="attestation">
                                        <h3>FRRO Facilitation

                                        </h3>
                                    </a>
                                    <p>FRRO facilitation refers to the support and services provided for foreigners
                                        seeking to comply with visa regulations in India through the Foreigners Regional
                                        Registration Office (FRRO). This includes assistance with visa extensions,
                                        residential permits, and other immigration-related processes. Our facilitation
                                        services streamline the FRRO procedures, ensuring that expatriates receive
                                        timely guidance and support to navigate the complexities of Indian immigration
                                        law. We help simplify the application process, making it easier for foreigners
                                        to stay compliant and focus on their work or studies in India.</p>
                                    <a routerLink="attestation" class="line-bnt">Read More</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>
        <!-- <div class="case-btn text-center">
            <p>We Have More Amazing Cases. <a routerLink="/">View More</a></p>
        </div> -->
    </div>
</section>

<section class="feature-area bg-color ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6">
                <div class="contnet">
                    <div class="feature-tittle">
                        <span>Features</span>
                        <h2> Why You Should Trust Us?</h2>
                        <p>Our primary focus is towards assisting the clients with navigating the legal and the
                            regulatory requirements that are related to business. With this purpose, our primary goal is
                            to become the partner of choice for the assistance related to company registration and
                            business management in India.</p>
                    </div>
                    <ul>
                        <li><i class="flaticon-correct"></i>
                            Recognized by Govt. of India</li>
                        <li><i class="flaticon-correct"></i>
                            Trained & Professional Experts</li>
                        <li><i class="flaticon-correct"></i>
                            Affordable </li>
                        <li><i class="flaticon-correct"></i>
                            Data Security & Trust</li>
                        <li><i class="flaticon-correct"></i>
                            Commitment to Accuracy</li>
                        <li><i class="flaticon-correct"></i>
                            10000+ Happy Customers</li>
                    </ul>
                    <a routerLink="/contact" class="box-btn">Lets Talk!</a>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="feature-img">
                    <img src="assets/images/feature-img.png" alt="feature" />
                </div>
            </div>
        </div>
    </div>
</section>

<section class="home-team-area ptb-100">
    <div class="container team-container">
        <div class="section-title">
            <span>Team Members</span>
            <h2>People Who are Behind the Achievements</h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. Quis ipsum suspendisse.</p> -->
        </div>
        <div class="home-team-slider">
            <owl-carousel-o [options]="teamSlides">
                <ng-template carouselSlide>
                    <div class="single-team team-member ">
                        <div class="team-img">
                            <img src="../../../../assets/images/team/RajendraSingh.jpg" alt="team" />

                            <!-- <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul> -->
                        </div>

                        <div class="content text-center">
                            <h3>
                                Rajendera Singh Chaggar</h3>
                            <p>Compliance and Regulatory Consultant</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team team-member">
                        <div class="team-img team-member">
                            <img src="../../../../assets/images/team/Naveen.jpg" alt="team" />

                            <!-- <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul> -->
                        </div>

                        <div class="content text-center">
                            <h3>Naveen Sehrawat</h3>
                            <p>Legal and Compliance Advisor</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team team-member">
                        <div class="team-img">
                            <img src="../../../../assets/images/team/Anand.jpg" alt="team" />

                            <!-- <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul> -->
                        </div>

                        <div class="content text-center">
                            <h3>Anand Venuganti</h3>
                            <p>Tax & Compliance Consultant </p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team team-member">
                        <div class="team-img">
                            <img src="../../../../assets/images/team/S.kajla.jpg" alt="team" />

                            <!-- <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul> -->
                        </div>

                        <div class="content text-center">
                            <h3>
                                Satyendra Kumar Kajla</h3>
                            <p>Legal & Compliance Specialist</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team team-member">
                        <div class="team-img">
                            <img src="../../../../assets/images/Attonerys/sourab.JPG" alt="team" />

                            <!-- <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul> -->
                        </div>

                        <div class="content text-center">
                            <h3>
                                Saurav D Kumar</h3>
                            <p>Founder & Principal Consultant</p>
                        </div>
                    </div>
                </ng-template>
                <!-- <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t2.jpg" alt="team" />

                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Evana Doe</h3>
                            <p>Web Developer</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t3.jpg" alt="team" />

                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Bread Mc</h3>
                            <p>IT Consulting</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-team">
                        <div class="team-img">
                            <img src="assets/images/team/t4.jpg" alt="team" />

                            <ul class="social">
                                <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                                <li><a href="#" target="_blank"><i class="bx bxs-envelope"></i></a></li>
                            </ul>
                        </div>

                        <div class="content text-center">
                            <h3>Maria Fread</h3>
                            <p>UI/UX Designer</p>
                        </div>
                    </div>
                </ng-template> -->
            </owl-carousel-o>
        </div>
    </div>
</section>

<!-- <section class="client-area ptb-100 bg-color">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>What Our Client’s Say</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque
                blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>
        <div class="client-wrap">
            <owl-carousel-o [options]="clientWrap">
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="" alt="img">
                        <p> I recently engaged LegalSetup for trademark services and was blown away by the
                            degree of professionalism and competence they provided. From beginning to end, the staff was
                            attentive and communicative, going above and beyond to ensure that all of my trademark
                            requirements were satisfied.
                        </p>
                        <h3>xxxxxxx</h3>
                        <span>xxxxxxxxxxxx</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="" alt="img">
                        <p>One of the best Trademark service provider in India. It has all the SOP for trademark filling
                            and for other services also which increase the transparency and confidence of the buyer.
                            Very affordable. You guys are doing amazing work. Keep it up. Thank you LegalSetup .
                        </p>
                        <h3>xxxxxxx</h3>
                        <span>xxxxxxxxxxx</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="" alt="img">
                        <p> I am amazed to get the trademark without any complications just contacting this firm, I had
                            to just submit my documents and fee, and the rest of work done by the commendable team of
                            the
                            firm.

                        </p>
                        <h3>xxxxxxxxx</h3>
                        <span>xxxxxxx</span>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-client">
                        <img src="" alt="img">
                        <p> I highly recommend the LegalSetup team. their response is very quick and they used to give
                            you proper advice on how to handle any matter. Very courteous and the overall service is
                            excellent.
                        </p>
                        <h3>xxxxx</h3>
                        <span>xxxxxxxxx</span>
                    </div>
                </ng-template>


            </owl-carousel-o>
        </div>
    </div>
</section> -->

<!-- BLOGS -->
<!-- <section class="home-blog-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Blog Post</span>
            <h2>Our Regular Blogs</h2>
            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. A facilis vel consequatur tempora atque
                blanditiis exercitationem incidunt, alias corporis quam assumenda dicta.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/1.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2020</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details">
                            <h3>Joe’s Company Software Development Case</h3>
                        </a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi
                            perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/5.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2020</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details">
                            <h3>Temperature App UX Studies & Development Case</h3>
                        </a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi
                            perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6 offset-md-3 offset-lg-0">
                <div class="single-blog">
                    <div class="blog-img">
                        <a routerLink="/blog-details"><img src="assets/images/blog/3.jpg" alt="blog"></a>
                    </div>

                    <div class="content">
                        <ul>
                            <li>10 April 2020</li>
                            <li><a routerLink="/blog">By Admin</a></li>
                        </ul>
                        <a routerLink="/blog-details">
                            <h3>IT Software Company Development Case</h3>
                        </a>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptas in fugit minima modi
                            perspiciatis nam aspernatur porro</p>
                        <a routerLink="/blog-details" class="line-bnt">Read More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="blog-btn text-center">
            <p>We Have More Usefull Blogs For You. <a routerLink="/blog">View More</a></p>
        </div>
    </div>
</section> -->

<!-- <section class="home-contact-area bg-color ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Contact Us</span>
            <h2>If You Have Any Query, Please Feel Free Contact Us</h2>
            <p>Are you seeking tailored solutions that perfectly fit your needs? Look no further. At LegalSetup, we
                specialize in providing customized solutions designed to address your unique challenges and goals.

            </p>
        </div>

        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="contact-img">
                    <img src="assets/images/contact-img.png" alt="contact">
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="content">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="name" id="name" class="form-control" required
                                        placeholder="Your Name" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="email" name="email" id="email" class="form-control" required
                                        placeholder="Your Email" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="phone_number" id="phone_number" required
                                        class="form-control" placeholder="Your Phone" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-sm-12">
                                <div class="form-group">
                                    <input type="text" name="msg_subject" id="msg_subject" class="form-control" required
                                        placeholder="Subject" />
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea name="message" class="form-control" id="message" cols="30" rows="5"
                                        required placeholder="Your Message"></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="default-btn page-btn box-btn">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section> -->