import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tax',
  templateUrl: './tax.component.html',
  styleUrls: ['./tax.component.scss']
})
export class TaxComponent {

  activeSection = 'overview';
  constructor() { }

  ngOnInit(): void {
  }
  // ViewChild references to each section
  @ViewChild('Pvt') Pvt!: ElementRef;
  @ViewChild('Limited') Limited!: ElementRef;
  @ViewChild('Filings') Filings!: ElementRef;
  @ViewChild('Labour') Labour!: ElementRef;
  @ViewChild('Convert') Convert!: ElementRef;
  @ViewChild('Tax') Tax!: ElementRef;


  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = this[sectionId];
    if (section) {
      section.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}