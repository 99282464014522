<header class="header-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-2 col-sm-0">
                <div class="logo">
                    <a routerLink="/"><img src="../../../../assets/images/logonavnoback.png" alt="logo" /></a>
                </div>
            </div>

            <div class="col-lg-8 col-sm-6 text-end pr-0">
                <div class="header-content-right">
                    <ul class="header-contact">
                        <li><a href="tel:+91 9911566199"><i class="bx bxs-phone-call"></i>+91 9911566199</a></li>
                        <li><a href="mailto:legalsetupgroup@gmail.com"><i
                                    class="bx bxs-envelope"></i>legalsetupgroup@gmail.com</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-2 col-sm-6 text-end pl-0">
                <div class="header-content-right">
                    <ul class="header-social">
                        <li><a href="https://www.linkedin.com/in/legal-setup-7297932b5/" target="_blank"><i
                                    class="bx bxl-linkedin"></i></a></li>
                        <li><a href="https://www.instagram.com/legalsetupgroup/profilecard/?igsh=MXd0c2d2azc4MG90MQ== "
                                target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="https://www.youtube.com/@legalsetup" target="_blank"> <i
                                    class="bx bxl-youtube"></i></a></li>
                        <li><a href="https://www.google.com/maps?ll=28.514217,77.165447&z=16&t=m&hl=en&gl=IN&mapclient=embed&cid=15508234987830063501"
                                target="_blank"> <i class="bx bx-location-plus"></i></a></li>

                    </ul>
                </div>
            </div>
        </div>
    </div>
</header>

<div class="{{navbarClass}}">
    <div class="main-nav" ngStickyNav stickyClass="sticky-box-shadow">
        <div class="container">
            <nav class="navbar navbar-expand-lg navbar-light" [class.active]="classApplied">
                <a class="navbar-brand d-none" routerLink="/">
                    <img src="assets/images/logo.png" alt="logo">
                </a>
                <button class="navbar-toggler" type="button" (click)="toggleClass()">
                    <span class="burger-menu">
                        <span class="top-bar"></span>
                        <span class="middle-bar"></span>
                        <span class="bottom-bar"></span>
                    </span>
                </button>
                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav text-left">
                       
                       
                        <li class="nav-item">
                            <a routerLink="startup" class="nav-link dropdown-toggle ">Startup</a>

                            <ul class="dropdown-menu">

                                <li class="nav-item"><a routerLink="startup" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Incorporation</a></li>
                                <li class="nav-item"><a routerLink="startup" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Company Master Documents
                                    </a></li>
                                <li class="nav-item"><a routerLink="startup" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">DSC Services
                                    </a></li>
                                <li class="nav-item"><a routerLink="startup" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">DIN Services
                                    </a></li>
                                <li class="nav-item"><a routerLink="startup" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Company/LLP Name Availability
                                    </a></li>

                                <!-- DIFFERENT HOMEPAGE TEMPLATE -->
                                <!-- <li class="nav-item"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                                <li class="nav-item"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>

                                <li class="nav-item"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Three</a></li> -->
                            </ul>
                        </li>

                        <!-- <li class="nav-item">
                            <a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About</a>
                        </li> -->

                        <li class="nav-item">
                            <a routerLink="license" class="nav-link dropdown-toggle">License</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="license" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">GST
                                        Registration
                                    </a></li>

                                <li class="nav-item"><a routerLink="license" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">MSME
                                        Registration
                                    </a></li>
                                <li class="nav-item"><a routerLink="license" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">80 IAC Tax
                                        Exemption

                                    </a></li>
                                <li class="nav-item"><a routerLink="license" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Importer
                                        Exporter Code Registration

                                    </a></li>
                                <li class="nav-item"><a routerLink="license" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">FSSAI
                                        Registration

                                    </a></li>
                                <li class="nav-item"><a routerLink="license" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Shop And
                                        Establishments Registration

                                    </a></li>
                                <li class="nav-item"><a routerLink="license" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">ESI
                                        Registration

                                    </a></li>
                                <li class="nav-item"><a routerLink="license" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">TAN
                                        Registration

                                    </a></li>

                                <li class="nav-item"><a routerLink="license" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">PAN
                                        Registration
                                    </a></li>

                                <li class="nav-item"><a routerLink="license" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">PF
                                        Registration

                                    </a></li>

                                <li class="nav-item"><a routerLink="license" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Drug License

                                    </a></li>


                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="complaince-tax" class="nav-link dropdown-toggle">Complaince & Tax
                            </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="complaince-tax" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Changes in
                                        Pvt Ltd Company
                                    </a></li>

                                <li class="nav-item"><a routerLink="complaince-tax" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Changes in
                                        Limited Liability Partnership
                                    </a></li>

                                <li class="nav-item"><a routerLink="complaince-tax" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Mandatory
                                        Annual Filings

                                    </a></li>


                                <li class="nav-item"><a routerLink="complaince-tax" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Labour
                                        Compliance
                                    </a></li>

                                <li class="nav-item"><a routerLink="complaince-tax" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Convert Your
                                        Business
                                    </a></li>

                                <li class="nav-item"><a routerLink="complaince-tax" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">GST & Other
                                        Indirect Tax
                                    </a></li>

                                <li class="nav-item"><a routerLink="complaince-tax" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Accounting &
                                        Tax

                                    </a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="documentation" class="nav-link dropdown-toggle">Documentation</a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="documentation" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Business Contracts
                                    </a></li>

                                <li class="nav-item"><a routerLink="documentation" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Setup of HUF
                                    </a></li>

                                <li class="nav-item"><a routerLink="documentation" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Real Estate

                                    </a></li>

                                <li class="nav-item"><a routerLink="documentation" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Notices
                                    </a></li>

                                <li class="nav-item"><a routerLink="documentation" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">HR Policies
                                    </a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="attestation" class="nav-link dropdown-toggle">Attestation Services
                            </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="attestation" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Apostille</a></li>

                                <li class="nav-item"><a routerLink="attestation" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Embassy Attestation
                                    </a></li>

                                <li class="nav-item"><a routerLink="attestation" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Legalization</a></li>

                                <li class="nav-item"><a routerLink="attestation" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Chamber of
                                        Commerce Attestation
                                    </a></li>

                                <li class="nav-item"><a routerLink="attestation" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Offical Translation</a></li>

                                <li class="nav-item"><a routerLink="attestation" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">HRD Attestation
                                    </a></li>

                                <li class="nav-item"><a routerLink="attestation" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">FRRO Facilitation</a></li>

                                <!-- <li class="nav-item"><a routerLink="/faq" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">FAQ</a></li>

                                <li class="nav-item"><a routerLink="/terms-condition" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Terms &
                                        Conditions</a></li>

                                <li class="nav-item"><a routerLink="/privacy-policy" cl ass="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Privacy
                                        Policy</a></li> -->
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a routerLink="trademark" class="nav-link dropdown-toggle">Trademark & IPR

                            </a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="trademark" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Trademark</a></li>

                                <li class="nav-item"><a routerLink="trademark" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Copyright
                                    </a></li>

                                <li class="nav-item"><a routerLink="trademark" class="nav-link" routerLinkActive="active"
                                        [routerLinkActiveOptions]="{exact: true}">Patent</a></li>

                                <li class="nav-item"><a routerLink="trademark" class="nav-link"
                                        routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Infringement
                                    </a></li>

                            </ul>
                        </li>




                        <!-- <li class="nav-item"><a routerLink="/contact" class="nav-link">Contact </a></li> -->
                    </ul>
                </div>
                <!-- <div class="nav-right">
                    <form>
                        <div class="input-group">
                            <input type="text" class="form-control search" placeholder="Search..." />
                        </div>

                        <button type="submit">
                            <i class="bx bx-search"></i>
                        </button>
                    </form>
                </div> -->
                <div class="nav-btn">
                    <a routerLink="/contact" class="box-btn">Contact</a>
                </div>
            </nav>
        </div>
    </div>
</div>