<!-- <div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Solutions Details</h2>
            <ul>
                <li><a routerLink="/">Home </a></li>
                <li class="active">Solutions Details</li>
            </ul>
        </div>
    </div>

    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div> -->

<!-- <section class="services-details-area ptb-100"> -->
<div class="page-title-area">

    <div class="container">
        <div class="registration-container">
            <div class="text-section">
                <h1><span>Attestation Services</span> Verification Made Easy

                </h1>
                <p>
                    LegalSetup offers a comprehensive suite of Attestation Services to simplify your documentation
                    needs. Our services include Apostille for international use, Embassy Attestation for authentication,
                    and Legalization to validate documents for foreign jurisdictions. We also provide Chamber of
                    Commerce Attestation, professional Translation services, and HRD Attestation for educational
                    documents. Additionally, our FRRO Facilitation services ensure a smooth process for visa and
                    immigration needs. Trust us to handle the complexities of attestation, so you can focus on your
                    global ventures with confidence.

                </p>
                <div class="stats">
                    <div>
                        <h2>1000+</h2>
                        <p>Happy Customers</p>
                    </div>
                    <div>
                        <h2>20 +</h2>
                        <p>CA & Lawyers</p>
                    </div>
                    <div>
                        <h2>500 +</h2>
                        <p>Filings Every Month</p>
                    </div>
                </div>
				
				<div class="buttons">
                    <a href="tel:+91 9911566199" target="_blank"><button  class="btn-schedule">Schedule Free Consultation</button></a>
                    <button routerLink="/about" class="btn-info">See How It Works</button>
                </div>
            </div>
            <div class="image-section">
                <img src="../../../assets/images/apostillecerti.jpg" alt="Certificate">
                <img src="../../../../assets/images/google-review.png" alt="Google Customer Reviews"
                    class="review-badge">
            </div>
        </div>
    </div>
    <div class="page-shape">
        <div class="shape1"><img src="assets/images/shape/1.png" alt="shape" /></div>
        <div class="shape3"><img src="assets/images/shape/3.png" alt="shape" /></div>
        <div class="shape4"><img src="assets/images/shape/4.png" alt="shape" /></div>
        <div class="shape5"><img src="assets/images/shape/5.png" alt="shape" /></div>
        <div class="shape6"><img src="assets/images/shape/6.png" alt="shape" /></div>
    </div>
</div>
<!-- </section> -->

<div class="container">
    <!-- Fixed Sidebar with Buttons to Navigate to Sections -->
    <div class="sidebar">
        <button class="sidebar-btn" (click)="scrollToSection('Apostille')"
            [class.active]="activeSection === 'Apostille'">Apostille</button>

        <button class="sidebar-btn" (click)="scrollToSection('Embassy')" [class.active]="activeSection === 'Embassy'">Embassy Attestation
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Legalization')"
            [class.active]="activeSection === 'Legalization'">Legalization

        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Commerce')" [class.active]="activeSection === 'Commerce'">Chamber of Commerce Attestation
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('Translation')" [class.active]="activeSection === 'Translation'">Offical Translation
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('HRD')" [class.active]="activeSection === 'HRD'">HRD Attestation
        </button>

        <button class="sidebar-btn" (click)="scrollToSection('FRRO')" [class.active]="activeSection === 'FRRO'">FRRO Facilitation
        </button>

     

    </div>

    <!-- Main Content Area with Anchored Sections -->
    <div class="content-wrapper">



        <div #Apostille class="content-section">

            <h1>Apostille Services</h1>
            <section id="apostille">
                <p><strong>Purpose:</strong> Apostille is a document authentication method required by countries that are part of the Hague Convention of 1961. It authenticates documents like educational degrees, marriage and birth certificates, and commercial documents for international use.</p>
                
                <h3>Process of Getting Apostille</h3>
                <ul>
                    <li><strong>Identify Document Type:</strong> Determine if your document is personal, educational, or commercial.</li>
                    <li><strong>Pre-Authentication:</strong> Get verification from relevant authorities (e.g., universities or regional authorities) before apostille.</li>
                    <li><strong>Submit to Ministry:</strong> Submit the pre-authenticated document to the Ministry of External Affairs (MEA) or equivalent office for the apostille.</li>
                    <li><strong>Apostille Issuance:</strong> MEA affixes an apostille stamp, confirming the document’s legitimacy for use abroad.</li>
                </ul>
                <p><strong>Importance:</strong> Apostille is essential for individuals relocating abroad for work, education, or residence, and for businesses in international trade.</p>
            </section>

        </div>

        <hr>

        <div #Embassy class="content-section">

            <h1>Embassy Attestation</h1>
    <section id="embassy-attestation">
        <p><strong>Purpose:</strong> Embassy attestation is a multi-step certification process for countries not in the Hague Convention, involving document validation at multiple levels, including by the foreign embassy.</p>
        
        <h3>Process of Getting Embassy Attestation</h3>
        <ul>
            <li><strong>Document Verification:</strong> Initial verification from local or state authorities, such as a notary public.</li>
            <li><strong>MEA Attestation:</strong> Document goes to MEA for certification after state verification.</li>
            <li><strong>Embassy Attestation:</strong> Submit the MEA-certified document to the relevant foreign embassy for final verification.</li>
        </ul>
        <p><strong>Importance:</strong> Essential for individuals moving to non-Hague countries for work, study, or residence.</p>
    </section>

        </div>

        <hr>

        <div #Legalization class="content-section">

            <h1>Legalization</h1>
    <section id="legalization">
        <p><strong>Purpose:</strong> Legalization is a verification process for document authentication in countries outside the Hague Convention, involving multiple layers of verification.</p>
        
        <h3>Process of Legalization</h3>
        <ul>
            <li><strong>Primary Verification:</strong> Local or state authority verification in the issuing country.</li>
            <li><strong>Central Government Attestation:</strong> Submission to the MEA or relevant authority for authentication.</li>
            <li><strong>Foreign Embassy Legalization:</strong> Document submitted to the embassy of the destination country for final legalization.</li>
        </ul>
        <p><strong>Importance:</strong> Required for international employment, business, or study in non-apostille countries.</p>
    </section>

        </div>

        <hr>


        <div #Commerce class="content-section">

            <h1>Chamber of Commerce Attestation</h1>
            <section id="chamber-of-commerce">
                <p><strong>Purpose:</strong> Chamber of Commerce attestation is needed for commercial documents, like invoices or company licenses, verifying their legitimacy for international trade.</p>
                
                <h3>Process of Chamber of Commerce Attestation</h3>
                <ul>
                    <li><strong>Document Preparation:</strong> Gather necessary commercial documents.</li>
                    <li><strong>Submission:</strong> Submit documents to a recognized Chamber of Commerce in the issuing country.</li>
                    <li><strong>Certification:</strong> The Chamber authenticates the document, making it eligible for further attestations if needed.</li>
                </ul>
                <p><strong>Importance:</strong> Enables companies to meet foreign legal requirements for international trade.</p>
            </section>

        </div>

        <hr>


        <div #Translation class="content-section">

            <h1>Official Translation</h1>
            <section id="official-translation">
                <p><strong>Purpose:</strong> Official translation services convert documents into the required language, ensuring their accuracy and legal acceptance abroad.</p>
                
                <h3>Process of Getting Official Translation</h3>
                <ul>
                    <li><strong>Choose a Certified Translator:</strong> Engage a translator recognized by the authorities of the destination country.</li>
                    <li><strong>Submit Original Document:</strong> Provide the document for translation with the target language specified.</li>
                    <li><strong>Translation and Certification:</strong> The translator provides a certified, often notarized, translation.</li>
                    <li><strong>Attestation (If Required):</strong> Some countries may require further attestation of the translated document.</li>
                </ul>
                <p><strong>Importance:</strong> Ensures legal recognition of documents in foreign countries by translating them accurately into the local language.</p>
            </section>
        

        </div>

        <hr>



        <div #HRD class="content-section">

            <h1>HRD Attestation</h1>
            <section id="hrd-attestation">
                <p><strong>Purpose:</strong> Human Resource Development (HRD) attestation verifies educational documents, certifying that they were issued by recognized institutions.</p>
                
                <h3>Process of Getting HRD Attestation</h3>
                <ul>
                    <li><strong>Educational Document Verification:</strong> Submit documents to the HRD department in the state of the issuing institution.</li>
                    <li><strong>Verification by Issuing Institution:</strong> HRD may verify the document with the educational institution.</li>
                    <li><strong>HRD Stamp/Seal:</strong> The HRD department affixes its attestation, certifying the document for further processes.</li>
                </ul>
                <p><strong>Importance:</strong> Necessary for students and professionals going abroad, as foreign institutions may require HRD-attested degrees.</p>
            </section>

        </div>

        <hr>



        <div #FRRO class="content-section">

            <h1>FRRO Facilitation</h1>
    <section id="frro-facilitation">
        <p><strong>Purpose:</strong> The Foreigners Regional Registration Office (FRRO) oversees visa and residential permits for foreign nationals in India, enabling them to reside and work legally.</p>
        
        <h3>Process of Getting FRRO Facilitation</h3>
        <ul>
            <li><strong>Registration Application:</strong> Foreign nationals must apply for FRRO registration within 14 days of arrival in India.</li>
            <li><strong>Document Submission:</strong> Submit required documents like passport, visa, residential address, and employer verification.</li>
            <li><strong>Approval and Issue of Permit:</strong> Upon verification, FRRO grants a residential permit and visa endorsement.</li>
            <li><strong>Periodic Renewals:</strong> Foreign nationals may need to renew FRRO registration based on their visa type.</li>
        </ul>
        <p><strong>Importance:</strong> Ensures foreign nationals comply with Indian immigration laws, avoiding penalties or deportation.</p>
    </section>

        </div>

        <hr>



      
    </div>
</div>