import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-startup',
  templateUrl: './startup.component.html',
  styleUrls: ['./startup.component.scss']
})
export class StartupComponent {

  activeSection = 'overview';
  constructor() { }

  ngOnInit(): void {
  }
  // ViewChild references to each section
  @ViewChild('Incorporation') Incorporation!: ElementRef;
  @ViewChild('CompanyMasterDocuments') CompanyMasterDocuments!: ElementRef;
  @ViewChild('DSCServices') DSCServices!: ElementRef;
  @ViewChild('DINServices') DINServices!: ElementRef;
  @ViewChild('Company') Company!: ElementRef;

  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = this[sectionId];
    if (section) {
      section.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}