import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-trademark',
  templateUrl: './trademark.component.html',
  styleUrls: ['./trademark.component.scss']
})
export class TrademarkComponent {

  activeSection = 'overview';
  constructor() { }

  ngOnInit(): void {
  }
  // ViewChild references to each section
  @ViewChild('Trademark') Trademark!: ElementRef;
  @ViewChild('Copyright') Copyright!: ElementRef;
  @ViewChild('Patent') Patent!: ElementRef;
  @ViewChild('Infringement') Infringement!: ElementRef;
  @ViewChild('Company') Company!: ElementRef;

  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = this[sectionId];
    if (section) {
      section.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
