import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss']
})
export class LicenseComponent {
  activeSection = 'overview';
  constructor() { }

  ngOnInit(): void {
  }
  // ViewChild references to each section
  @ViewChild('GSTRegistration') GSTRegistration!: ElementRef;
  @ViewChild('MSME') MSME!: ElementRef;
  @ViewChild('IAC') IAC!: ElementRef;

  @ViewChild('Importer') Importer!: ElementRef;
  @ViewChild('FSSAI') FSSAI!: ElementRef;
  @ViewChild('Shop') Shop!: ElementRef;
  @ViewChild('ESI') ESI!: ElementRef;
  @ViewChild('TAN') TAN!: ElementRef;
  @ViewChild('PAN') PAN!: ElementRef;
  @ViewChild('PF') PF!: ElementRef;
  @ViewChild('Drug') Drug!: ElementRef;



  scrollToSection(sectionId: string) {
    this.activeSection = sectionId;
    const section = this[sectionId];
    if (section) {
      section.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
